// Built-in import
import * as React from "react";

// External import
import { Stack, Typography, alpha } from "@mui/material";

import { IServerProperty } from "@/store/slices/property";
import { useTranslation } from "react-i18next";

interface IProjectProfitProps {
  item: IServerProperty;
}

const ProjectProfit: React.FC<IProjectProfitProps> = ({ item }) => {
  // Getting translation object
  const { t } = useTranslation(["common"]);
  return (
    <Stack
      direction="row"
      justifyContent={"space-between"}
      flexWrap="wrap"
      sx={{
        background: (t) => alpha(t.palette.primary.main, 0.2),
        p: 1,
        borderRadius: 1,
      }}
    >
      <Typography variant="smallMedium" color="text.primary">
        {t("common:project_profit")}:
      </Typography>
      <Typography variant="smallMedium" color="text.primary">
        {item?.average_profit ? `${item?.average_profit}%` : "-"}
      </Typography>
    </Stack>
  );
};

export default ProjectProfit;
