import * as React from "react";

import { IconButton, Stack, SxProps, Theme, Typography } from "@mui/material";

import { DeleteIcon } from "@/components/icons";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import { useImageUploaded } from "@/hook";
import { useTranslation } from "react-i18next";

interface IGalleryItemProps {
  item: any;
  index: number;
  handleRemove: (index: number) => void;
  onItemClick?: (item: any, index: number) => void;
  isPrimary?: boolean;
  hasPrimaryChange?: boolean;
  showActions?: boolean;
}

const GalleryItem: React.FunctionComponent<IGalleryItemProps> = ({
  item,
  index,
  handleRemove,
  onItemClick,
  showActions = true,
  isPrimary = false,
  hasPrimaryChange = false,
}) => {
  // Style Main Image
  const styleBoxImage: SxProps<Theme> = {
    width: "100%",
    height: "220px",
    borderRadius: "12px",
    overflow: "hidden",
    position: "relative",
    boxShadow: "0px 3px 10px #99999960",
    cursor: "pointer",
    "& img": { objectFit: "cover", width: "100%", height: "100%" },
    [`&:hover .cover-symbol`]: {
      opacity: 1,
    },
  };

  // Style main image
  const styleMainImage = {
    position: "absolute",
    bottom: 7,
    left: 7,
    background: (t) => t.palette.common.white,
    px: 1,
    py: 0.3,
    borderRadius: 2,
    opacity: isPrimary ? 1 : 0,
    transition: "0.2s all",
  };

  const styleIconBtn = {
    position: "absolute",
    top: 8,
    right: 8,
  };

  //Getting translation object
  const { t } = useTranslation(["common"]);

  const mediaSource = useImageUploaded(item);

  if (!mediaSource) return null;

  return (
    <Stack
      sx={styleBoxImage}
      alignItems="end"
      onClick={() => onItemClick(item, index)}
    >
      {showActions && (
        <IconButton
          sx={styleIconBtn}
          onClick={(e) => {
            e.stopPropagation();
            handleRemove(index);
          }}
        >
          <DeleteIcon />
        </IconButton>
      )}

      <img src={mediaSource} alt="" />
      {Boolean(hasPrimaryChange) && (
        <Stack
          className="cover-symbol"
          sx={styleMainImage}
          direction="row"
          spacing={0.5}
        >
          <ImageOutlinedIcon color="primary" />
          <Typography variant="smallBold">{t("common:main_cover")}</Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default GalleryItem;
