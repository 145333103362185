// Built-in import
import * as React from "react";
// External import
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface IUntilAmountCapital {}

const UntilAmountCapital: React.FC<IUntilAmountCapital> = () => {
  // Getting translation object
  const { t } = useTranslation(["common"]);
  return (
    <Typography
      variant="subtitleBold"
      width={{ xs: "100%", md: "100px" }}
      textAlign={"center"}
      alignItems="end"
      justifyContent="center"
      height={{ xs: "auto", md: "60px" }}
      display="flex"
    >
      - {t("common:until")} -
    </Typography>
  );
};

export default UntilAmountCapital;
