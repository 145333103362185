//Built-in import
import * as React from "react";
//External import

import { Box } from "@mui/material";

interface ISpaceProps {
  height: number;
}

const Space: React.FunctionComponent<ISpaceProps> = ({ height }) => {
  return <Box sx={{ height }}></Box>;
};

export default React.memo(Space);
