import * as React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Paper, SxProps, Theme } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { alpha } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

interface ISearchBoxProps {
  sxProps?: SxProps<Theme>;
  onChange?: Function;
}

const SearchBox: React.FunctionComponent<ISearchBoxProps> = ({
  sxProps,
  onChange,
}) => {
  const [search, setSearch] = React.useState("");
  const { t } = useTranslation();
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e.target.value);
    setSearch(e.target.value);
  };
  return (
    <Paper
      component="form"
      sx={{
        p: 1,
        display: "flex",
        alignItems: "center",
        boxShadow: "none",
        borderRadius: "12px",
        my: 1,
        border: "1px solid",
        borderColor: (t) => t.palette.grey[300],
        "& .MuiInputBase-root": {
          width: "100%",
        },
        ...sxProps,
      }}
    >
      <SearchIcon
        sx={{
          mr: 1,

          color: (t) => alpha(t.palette.common.black, 0.5),
        }}
      />
      <InputBase placeholder={t("do_a_search")} onChange={handleChangeSearch} />
    </Paper>
  );
};

export default SearchBox;
