//Built-in import
import * as React from "react"

import AttachFileIcon from "@mui/icons-material/AttachFile"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
//External import
import { IconButton } from "@mui/material"

interface IChatboxInputButtonAttachProps {
  hiddenInput: any
  hasFile?: boolean
  fileRemover?: () => void
}

const ChatboxInputButtonAttach: React.FunctionComponent<
  IChatboxInputButtonAttachProps
> = ({ hiddenInput, hasFile = false, fileRemover }) => {
  return (
    <IconButton
      component={!hasFile ? "label" : "button"}
      onClick={() => {
        if (hasFile && fileRemover) {
          fileRemover()
        }
      }}
      sx={{
        bgcolor: (t) => (hasFile ? t.palette.primary.main : "transparent")
      }}
    >
      {hiddenInput}
      {hasFile ? <DeleteOutlineIcon /> : <AttachFileIcon />}
    </IconButton>
  )
}

export default ChatboxInputButtonAttach
