import * as React from "react";

import {
  Autocomplete,
  CircularProgress,
  Skeleton,
  TextField,
} from "@mui/material";

import { IResponseForFetchPaginationEntity } from "@/api/types";
import { api } from "@/api";
import { getUrlWithQueryParams } from "@/utils";
import { useTranslation } from "react-i18next";

interface IMyAutoCompleteProps {
  id: string;
  label: string;
  endpoint?: string;
  multiple?: boolean;
  disableClearable?: boolean;
  items?: any[];
  optionLabel: (option: any) => string;
  isOptionEqualToValue: (option: any, value: any) => boolean;
  onChange?: (e: any, value: any) => void;
  parameterToSearch?: string;
  defaultValue: any;
  disabled?: boolean;
  queries?: object;
  sx?: object;
}

const MyAutoComplete: React.FunctionComponent<IMyAutoCompleteProps> = ({
  id,
  label,
  endpoint,
  multiple = false,
  items = [],
  optionLabel,
  isOptionEqualToValue,
  parameterToSearch = "s",
  onChange,
  defaultValue,
  disabled = false,
  queries,
  disableClearable = false,
  sx = {},
}) => {
  const { t } = useTranslation(["common"]);

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly any[]>(items || []);

  const [firstLoading, setFirstLoading] = React.useState(true);
  const [loading, setLoading] = React.useState((endpoint && true) || false);
  const [searched, setSearched] = React.useState("");
  React.useEffect(() => {
    let active = true;
    if (endpoint) {
      if (active) {
        setLoading(true);
        api
          .get<IResponseForFetchPaginationEntity<any>>(
            parameterToSearch
              ? getUrlWithQueryParams(endpoint, {
                  ...(searched
                    ? {
                        [parameterToSearch]: searched,
                      }
                    : {}),
                  ...(Boolean(queries) && queries),
                })
              : endpoint
          )
          .then((res) => {
            setOptions(res.data.data.items.data || []);
            setLoading(false);
            if (firstLoading) setFirstLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      }
    }

    return () => {
      active = false;
    };
  }, [searched]);

  React.useEffect(() => {
    if (items.length) setFirstLoading(false);
  }, [items]);

  return (
    (!firstLoading && (
      <>
        <Autocomplete
          sx={sx}
          multiple={multiple}
          id={id}
          open={open}
          defaultValue={defaultValue}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          isOptionEqualToValue={isOptionEqualToValue}
          getOptionLabel={optionLabel}
          options={options}
          loading={loading}
          loadingText={`${t("common:is_loading")} ...`}
          onChange={onChange}
          disabled={disabled}
          disableClearable={disableClearable}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearched(e.target.value)
              }
              label={label}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </>
    )) || <Skeleton sx={{ width: "100%", height: 80 }} />
  );
};

export default MyAutoComplete;
