import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type IPaletteMode = "dark" | "light";

//Initial state type
interface ISettingState {
  paletteMode: IPaletteMode;
}

//Initial state
const initialState: ISettingState = {
  paletteMode: "light",
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setPaletteMode(state, action: PayloadAction<IPaletteMode>) {
      state.paletteMode = action.payload;
    },
  },
});

export const { setPaletteMode: actionSetPalette } = settingsSlice.actions;

export default settingsSlice.reducer;
