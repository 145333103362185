import * as React from "react"

import { Stack, Typography } from "@mui/material"

import { IServerProperty } from "@/store/slices/property"
import { ProgressWithValue } from "@/components/shared"

interface IInvestmentStockProgressProps {
  item: IServerProperty
  hasAmount?: boolean
}

const InvestmentStockProgress: React.FunctionComponent<
  IInvestmentStockProgressProps
> = ({ item, hasAmount = true }) => {
  let targetPercent = +item?.total_investment_percent || 0

  targetPercent = targetPercent > 100 ? 100 : targetPercent

  return (
    <Stack spacing={1}>
      {hasAmount && (
        <Typography variant="smallMedium" color="text.primary">
          {(item.total_investment_quantity &&
            item.total_investment_quantity.withCommas().withPriceUnit()) ||
            "-"}
        </Typography>
      )}
      <ProgressWithValue
        value={targetPercent}
        sxProps={{
          "& .MuiLinearProgress-root": { borderRadius: "8px" }
        }}
      />
    </Stack>
  )
}

export default InvestmentStockProgress
