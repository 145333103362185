import "./i18n/i18n";
import "@/extends/extendedPrototypes";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { persistor, store } from "./store/store";

import { CssBaseline } from "@mui/material";
import ErrorBoundary from "./error-bundry";
import { GlobalContextProvider } from "./context";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import RTL from "./mui/rtl/RTL";
import RouteElements from "./navigation";
import { ToastContainer } from "./components/shared/toaster";

function App() {
  return (
    <ErrorBoundary>
      <RTL>
        <Provider store={store}>
          <GlobalContextProvider>
            <PersistGate loading={<>Loading store ...</>} persistor={persistor}>
              <CssBaseline />
              <ToastContainer />
              <RouteElements />
            </PersistGate>
          </GlobalContextProvider>
        </Provider>
      </RTL>
    </ErrorBoundary>
  );
}

export default App;
