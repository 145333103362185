const numberToTimeFormat = (n: number, ignoreHours: boolean = false) => {
  let sec_num = parseInt(String(n), 10);
  let hours: number | string = Math.floor(sec_num / 3600);
  let minutes: number | string = Math.floor((sec_num - hours * 3600) / 60);
  let seconds: number | string = sec_num - hours * 3600 - minutes * 60;

  let output = "";

  if (!ignoreHours) {
    output += (hours < 10 ? "0" + hours : hours) + ":";
  }

  output +=
    (minutes < 10 ? "0" + minutes : minutes) +
    ":" +
    (seconds < 10 ? "0" + seconds : seconds);

  return {
    text: output,
    minutes,
    seconds,
    hours,
  };
};

export default numberToTimeFormat;
