import * as React from "react";

import { IEvent } from "@/types/event";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface INewsStepProps {}

const NewsStep: React.FunctionComponent<INewsStepProps> = (props) => {
  //Getting trannslation object
  const { t } = useTranslation(["common"]);

  return (
    <Typography sx={{ textAlign: "center" }} variant="bodyMedium">
      {t("common:news_step_isnt_changeable")}
    </Typography>
  );
};

export default NewsStep;
