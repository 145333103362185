// Built-in import
import * as React from "react";
// External import
import { Stack, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useGlobalContext } from "@/context/global-context";
// Internal import
import ModalContentWrapper from "@/components/shared/modal/modal-content-wrapper";
import { IEvent } from "@/types/event";
import { LoadingButton } from "@mui/lab";
import { toaster } from "@/components/shared/toaster";
import { Report } from "@/services";

interface IDeleteEventModalContent {
  event?: IEvent;
  onChangeEvents?: () => void;
}

const DeleteEventModalContent: React.FC<IDeleteEventModalContent> = ({
  event,
  onChangeEvents,
}) => {
  // Modal
  const { closeModal } = useGlobalContext();
  // Getting translation object
  const { t } = useTranslation(["common"]);

  const [loading, setLoading] = React.useState(false);
  // handle delete
  const handleDelete = async () => {
    setLoading(true);
    try {
      const res = await Report.DeleteEvent(event.type, event.id);
      toaster.success(res.data.message);
      onChangeEvents();
      closeModal();
    } catch (error) {
      toaster.error(error.response.data);
    } finally {
      setLoading(false);
    }
  };
  return (
    <ModalContentWrapper title={t("common:delete_event")}>
      <Stack spacing={2} alignItems="center">
        <Stack spacing={0.5} alignItems="center">
          <Typography variant="subtitleBold" color="text.primary">
            {t("common:are_you_sure_you_want_to_delete_event")}
          </Typography>
          <Typography variant="bodyMedium" color="text.secondary">
            {t("common:event_name", {
              title: event?.title || t("common:without_title"),
            })}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <LoadingButton
            loading={loading}
            variant="contained"
            color="error"
            onClick={handleDelete}
          >
            {t("common:delete_event")}
          </LoadingButton>
          <Button variant="outlined" color="primary" onClick={closeModal}>
            {t("common:cancel")}
          </Button>
        </Stack>
      </Stack>
    </ModalContentWrapper>
  );
};

export default DeleteEventModalContent;
