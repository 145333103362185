import * as React from "react"

import { IMedia } from "@/types/media"
import { Stack } from "@mui/material"

interface ITicketMessageImageProps {
  media: IMedia
}

const TicketMessageImage: React.FunctionComponent<ITicketMessageImageProps> = ({
  media
}) => {
  return (
    <Stack
      sx={{
        [`& img`]: {
          maxWidth: 280,
          height: "auto"
        }
      }}
    >
      <img alt={media.file_name} src={media.original_url} />
    </Stack>
  )
}

export default TicketMessageImage
