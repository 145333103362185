// Built-in import
import * as React from "react";

import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";

import AddEventModalContent from "./modal";
// External import
import { Button } from "@mui/material";
import { PlusIcon } from "@/components/icons";
// internal import
import { useGlobalContext } from "@/context/global-context";
import { useTranslation } from "react-i18next";

interface IAddEventButton {
  propertyId?: string;
  onFetchAllEvents?: () => void;
}

const AddEventButton: React.FC<IAddEventButton> = ({
  propertyId,
  onFetchAllEvents,
}) => {
  // Style time line
  const timelineStyles = {
    width: "100%",
    ml: "-12px",
    "&::before": {
      display: "none",
    },
    "& .MuiTimelineDot-root": {
      alignItems: "center",
      pt: 0.5,
      justifyContent: "center",
      width: "50px !important",
      height: "50px !important",
      background: (t) => t.palette.primary.main + " !important",
    },
  };
  // modal
  const { showModal } = useGlobalContext();
  // Getting translation object
  const { t } = useTranslation(["common"]);
  return (
    <TimelineItem sx={timelineStyles}>
      <TimelineSeparator>
        <TimelineDot>
          <PlusIcon width={30} height={30} />
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Button
          variant="contained"
          sx={{ width: ["100%", , "300px"] }}
          onClick={() =>
            showModal(
              <AddEventModalContent
                {...(propertyId && { propertyId })}
                onFetchALlEvents={onFetchAllEvents}
              />,
              {
                width: 850,
              }
            )
          }
        >
          {t("common:add_new_event")}
        </Button>
      </TimelineContent>
    </TimelineItem>
  );
};

export default AddEventButton;
