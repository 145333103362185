import type { IUser } from "@/types/user"
import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

//Initial state type
interface IAuthState {
  loading: boolean
  user: IUser | null
  token: null | string
}

//Initial state
const initialState: IAuthState = {
  loading: false,
  user: null,
  token: null
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoading(state) {
      state.loading = true
    },
    setLoadingOff(state) {
      state.loading = false
    },
    setUser(state, action: PayloadAction<IUser>) {
      state.user = action.payload
      state.loading = false
    },
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload
    },
    setLogout(state) {
      state.user = null
      state.token = null
      state.loading = false
    },
    reset(state) {
      state = initialState
    }
  }
})

export const {
  setUser: actionSetUser,
  setToken: actionSetToken,
  setLogout: actionSetLogout,
  setLoading: actionAuthSetLoading,
  setLoadingOff: actionAuthSetLoadingOff,
  reset: actionReset
} = authSlice.actions

export default authSlice.reducer
