// Built-in import
import * as React from "react"

import {
  AdditionalInformation,
  BasicPlanInformation,
  CapitalizatinDetails,
  ExecutiveCalender,
} from "./steps"

// External import

import { propertySelector } from "@/store/slices/property/selectors"
import { useAppSelector } from "@/store/store"
import { useQuery } from "@/hook"
import { IServerProperty } from "@/store/slices/property"
import { Report } from "@/services"
import { toaster } from "../toaster"
import { IEvent } from "@/types/event"
import { useParams } from "react-router-dom"
import { Stack } from "@mui/material"
import { AddPropertyWizard } from "./wizard"

interface IAddNewProperty {}

const AddNewProperty: React.FC<IAddNewProperty> = () => {
  const [wizardStep, setWizardStep] = React.useState(0)
  //access to query type
  const query = useQuery()
  //get Type of step
  const stepType = query.get("type")
  //checking for that we are in edit page or not
  const { id: isEdit } = useParams()
  const [receivedEvents, setReceivedEvents] = React.useState<IEvent[]>([])

  React.useEffect(() => {
    if (stepType) setWizardStep(+stepType)
  }, [stepType])

  //Getting property
  const property = useAppSelector(propertySelector) as IServerProperty

  console.log(property, "PROPERTY")

  const handleFetchAllEvents = async () => {
    if (property.id) {
      try {
        const res = await Report.RecieveProjectEvents(property?.id, "all", {})
        const newEvents = res.data.data.items.data
        setReceivedEvents(newEvents)
      } catch (error) {
        toaster.error(error.response.data)
      } finally {
      }
    }
  }

  React.useEffect(() => {
    if (Boolean(property.id) && !isEdit) {
      handleFetchAllEvents()
    }
  }, [property.id])

  const RenderContent = () => {
    switch (wizardStep) {
      case 0:
        return (
          <BasicPlanInformation property={property} isEdit={Boolean(isEdit)} />
        )
      case 1:
        return (
          <CapitalizatinDetails property={property} isEdit={Boolean(isEdit)} />
        )
      case 2:
        return (
          <AdditionalInformation property={property} isEdit={Boolean(isEdit)} />
        )
      case 3:
        return (
          <ExecutiveCalender
            onFetchAllEvents={handleFetchAllEvents}
            events={receivedEvents}
          />
        )
      default:
        return <BasicPlanInformation />
    }
  }

  return (
    <Stack>
      <AddPropertyWizard activeSteps={wizardStep} isEdit={Boolean(isEdit)} />
      <RenderContent />
    </Stack>
  )
}

export default AddNewProperty
