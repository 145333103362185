//Built-in Import
import * as React from "react";
//External Import
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { Paper, Stack, Typography } from "@mui/material";
import { ApexOptions } from "apexcharts";

interface IDonutChartProps {
  title: string;
  chartOptions?: ApexOptions;
  colors: string[];
  labels: string[];
  series: number[];
}

const DonutChart: React.FunctionComponent<IDonutChartProps> = ({
  title,
  chartOptions,
  colors,
  series,
  labels,
}) => {
  return (
    <Paper
      sx={{
        height: "100%",
        justifyContent: "space-between",
        display: "flex",
        flexDirection: "column",

        [`& *`]: {
          minHeight: "0 !important",
          fontFamily: "Vazir !important",
        },
        [`& .apexcharts-legend`]: {
          flexDirection: "column",
          [`& .apexcharts-legend-series`]: {
            display: "flex !important",
          },
          [`& .apexcharts-legend-marker`]: {
            marginRight: 1,
          },
        },
      }}
    >
      <Stack spacing={2}>
        <Typography variant="bodyMedium">{title}</Typography>
        <ReactApexChart
          options={{
            ...chartOptions,
            chart: { type: "donut" },
            colors,
            labels,
          }}
          series={series}
          type={"donut"}
        />
      </Stack>
    </Paper>
  );
};

export default DonutChart;
