import * as React from "react";

// import { api, getEndpoint } from "@/api"

// import { getUrlWithQueryParams } from "@/utils"

interface IFileUploaderOptions {
  defaultImage?: string;
  defaultFile?: File;
}

const useUploader = (options?: Partial<IFileUploaderOptions>) => {
  //State for storing file
  const [file, setFile] = React.useState<File | null | string>(null);
  const [inputKey, setInputKey] = React.useState(Date.now());
  const [fileDefault, setFileDefault] = React.useState<string | null>(null);
  React.useEffect(() => {
    //Setting default image
    if (options?.defaultImage) {
      if (typeof options.defaultImage === "object") {
        try {
          setFileDefault(URL.createObjectURL(options.defaultImage));
        } catch (e) {}
      } else {
        setFileDefault(options.defaultImage);
      }
    }
    //Setting default file
    if (options?.defaultFile) {
      setFile(options.defaultFile);
    }
  }, []);

  //Set file to our state
  const fileSetter = (file: File | null | string) => setFile(file);

  //Removing file from our state
  const fileRemover = () => {
    setFile(null);
    setFileDefault(null);
    setInputKey(Date.now());
  };

  //Checking for file availibility
  const hasFile = Boolean(file);

  //Image file
  const fileImage = React.useMemo(() => {
    if (!file) {
      //Checking if we have default image
      if (fileDefault) return fileDefault;
      return null;
    }
    if (typeof file === "string") {
      return file;
    }
    try {
      return URL.createObjectURL(file);
    } catch (e) {
      return null;
    }
  }, [file, fileDefault]);

  //Hidden input
  const hiddenInput = (
    <input
      hidden
      key={inputKey}
      type="file"
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        fileSetter((e.target.files && e.target.files?.[0]) || null);
      }}
    />
  );

  //Loading
  const [loading, setLoading] = React.useState(false);
  const [isUploaded, setUploaded] = React.useState(false);

  //Method for upload
  const upload = async (file: File, isImage: 0 | 1 = 1) => {
    const formData = new FormData();
    formData.append("file", file);
    // return api.post(
    //   getUrlWithQueryParams(getEndpoint("files"), {
    //     is_image: isImage ? 1 : 0
    //   }),
    //   formData
    // )
  };

  return {
    loading,
    setLoading,
    isUploaded,
    upload,
    hiddenInput,
    file,
    fileRemover,
    fileSetter,
    hasFile,
    fileImage,
  };
};

export default useUploader;
