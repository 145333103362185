import { IMedia } from "@/types/media"

const useImageUploaded = (file: any) => {
  if (
    typeof file === "object" &&
    Object.keys(file).includes("collection_name")
  ) {
    return (file as IMedia)?.original_url
  } else {
    try {
      return URL.createObjectURL(file)
    } catch (e) {
      return null
    }
  }
}
export default useImageUploaded
