import * as React from "react"

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import { LoadingButton } from "@mui/lab"

interface IAuthSubmitButtonProps {
  title: string
  loading?: boolean
  disabled?: boolean
  btnType?: "submit" | "button"
  handleClick?: Function
}

const AuthSubmitButton: React.FunctionComponent<IAuthSubmitButtonProps> = ({
  title,
  disabled,
  loading = false,
  btnType = "submit",
  handleClick
}) => {
  return (
    <LoadingButton
      disabled={disabled}
      loading={loading}
      type={btnType}
      fullWidth
      {...((btnType === "button" && { onClick: () => handleClick() }) || {})}
      variant="contained"
      sx={{
        color: (t) => t.palette.common.white
      }}
      startIcon={<ArrowForwardIosIcon fontSize="inherit" />}
    >
      {title}
    </LoadingButton>
  )
}

export default AuthSubmitButton
