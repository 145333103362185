import * as React from "react"

import { Button, Stack } from "@mui/material"
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined"
import { LoadingButton } from "@mui/lab"
import { useQuery } from "@/hook"
import { useTranslation } from "react-i18next"

interface IAddPropertyStepsActionsProps {
  handlePrevStep?: Function
  handleNextStep?: Function
  nextStepTitle?: string
  handleSaveProperty?: Function
  loading?: boolean
  isEdit?: boolean
  saveLoading?: boolean
}

export const AddPropertyStepsActions: React.FunctionComponent<
  IAddPropertyStepsActionsProps
> = ({
  handleNextStep,
  handlePrevStep,
  handleSaveProperty,
  loading = false,
  isEdit = false,
  saveLoading = false,
  nextStepTitle = "",
}) => {
  //get translation object
  const { t } = useTranslation()
  //access to wizard step
  const query = useQuery()
  //find wizard step type
  const wizardStepType = query.get("type")
  //made condition for we are being in edit page and just being in additional step
  const isAdditionalStep = Boolean(isEdit && Boolean(+wizardStepType !== 2))

  return (
    <Stack
      direction={"row"}
      width="100%"
      justifyContent={isAdditionalStep ? "space-between" : "flex-end"}
      alignItems="center"
    >
      {(isAdditionalStep && (
        <LoadingButton
          loading={saveLoading}
          variant={"contained"}
          endIcon={<SaveOutlinedIcon />}
          {...(handleSaveProperty && { onClick: () => handleSaveProperty() })}
        >
          {t("save_changes")}
        </LoadingButton>
      )) ||
        null}
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        my={2}
        justifyContent="flex-end"
      >
        <Button
          variant="outlined"
          {...(handlePrevStep && { onClick: () => handlePrevStep() })}
          disabled={!+wizardStepType ? true : +wizardStepType === 0}
        >
          {t("prev_step")}
        </Button>
        <LoadingButton
          variant="contained"
          loading={loading}
          {...(handleNextStep && { onClick: () => handleNextStep() })}
          type={"submit"}
        >
          {nextStepTitle
            ? nextStepTitle
            : +wizardStepType === 3
            ? t("send_property")
            : t("next_step")}
        </LoadingButton>
      </Stack>
    </Stack>
  )
}
