import { IMedia } from "@/types/media"
import React from "react"

const useMedia = (media: IMedia[], cln: string, isArray: boolean = false) => {
  const targetMedia = React.useMemo(() => {
    if (!media?.length) return null

    return media.filter((item) => item.collection_name === cln)
  }, [cln, media])

  if (!media?.length) return null

  if (isArray) return targetMedia as IMedia[]
  else return targetMedia[0] as IMedia
}
export default useMedia
