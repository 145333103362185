// Built-in import
import * as React from "react";

// Internal import
import { Tag, TitleWithValue } from "@/components/shared";

import { IProperty } from "@/types/properties";
// External import
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

interface IPropertiesCardDetails {
  item: IProperty;
}

const PropertiesCardDetails: React.FC<IPropertiesCardDetails> = ({ item }) => {
  // Getting translation object
  const { t } = useTranslation(["common"]);

  return (
    <Stack
      width={"100%"}
      sx={{
        borderLeft: ["none", , , "1px solid #99999940"],
        px: [0, , , 1.5],
        [`& .title-with-value:first-child`]: {
          pt: 0,
        },
        [`& .title-with-value:last-child`]: {
          pb: 0,
        },
        [`& .title-with-value`]: {
          borderBottom: "1px solid #99999940",
          py: "18px",
          "&:last-child": {
            borderBottom: "none",
          },
        },
      }}
    >
      <TitleWithValue
        title={t("common:required_capital")}
        value={item?.required_investment_quantity?.withCommas().withPriceUnit()}
        variantTitle="bodyMedium"
        variantValue="bodyBold"
        colorValue="black"
        justifyContent="space-between"
      />
      <TitleWithValue
        title={t("common:investor_profit")}
        value={`%${item?.average_profit}`}
        variantTitle="bodyMedium"
        variantValue="bodyBold"
        colorValue="black"
        justifyContent="space-between"
      />
      <TitleWithValue
        title={t("common:investment_coefficient")}
        value={(item?.investment_ratio || 0).withCommas()}
        variantTitle="bodyMedium"
        variantValue="bodyBold"
        colorValue="black"
        justifyContent="space-between"
      />

      <TitleWithValue
        title={t("common:documents")}
        value={
          <Stack direction="row" spacing={1}>
            {(item.collateral_type && <Tag title={item.collateral_type} />) ||
              "-"}
          </Stack>
        }
        variantTitle="bodyMedium"
        variantValue="bodyBold"
        colorValue="black"
        justifyContent="space-between"
        alignItems="center"
      />
    </Stack>
  );
};

export default PropertiesCardDetails;
