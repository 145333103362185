import * as React from "react";
import { Stack, Typography, Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

interface IUserAuthGuidanceProps {
  question: string;
  description: string;
  isDisabled?: boolean;
  handleClick: Function;
}

const UserAuthGuidance: React.FunctionComponent<IUserAuthGuidanceProps> = ({
  question,
  isDisabled = false,
  description,
  handleClick,
}) => {
  return (
    <Stack
      direction="row"
      width="100%"
      alignItems="center"
      justifyContent={"center"}
      sx={{ "& a": { textDecoration: "none" } }}
    >
      <Typography variant={"smallRegular"}>{question}</Typography>
      <Button
        disableRipple
        disabled={isDisabled}
        variant="text"
        onClick={() => handleClick()}
        sx={{ "&:hover": { bgcolor: "transparent" } }}
      >
        {description}
      </Button>
    </Stack>
  );
};

export default UserAuthGuidance;
