// Built-in import
import * as React from "react";

// External Import
import { Stack } from "@mui/material";

// Internal Import
import CheckCircleIcon from "@/components/icons/check-circle-icon";
import CloseCircleIcon from "@/components/icons/close-circle-icon";
import { LoadingButton } from "@mui/lab";

interface IVerifyRejectionButtonProps {
  verifyTitle: string;
  rejectionTitle: string;
  verifyOnclick?: Function;
  rejectionOnclick?: Function;
  isVerifyDisable?: boolean;
  isRejectDisable?: boolean;
  confirmloading?: boolean;
  rejectloading?: boolean;
}

const VerifyRejectionButton: React.FunctionComponent<
  IVerifyRejectionButtonProps
> = ({
  verifyTitle,
  rejectionTitle,
  isRejectDisable = false,
  isVerifyDisable = false,
  verifyOnclick = () => {},
  rejectionOnclick = () => {},
  confirmloading = false,
  rejectloading = false,
}) => {
  return (
    <Stack direction="row" spacing={1} justifyContent="end" p={1}>
      <LoadingButton
        variant="contained"
        startIcon={<CloseCircleIcon />}
        color="error"
        disabled={isRejectDisable}
        onClick={() => rejectionOnclick()}
        loading={rejectloading}
      >
        {rejectionTitle}
      </LoadingButton>
      <LoadingButton
        variant="contained"
        startIcon={<CheckCircleIcon />}
        color="primary"
        disabled={isVerifyDisable}
        onClick={() => verifyOnclick()}
        loading={confirmloading}
      >
        {verifyTitle}
      </LoadingButton>
    </Stack>
  );
};

export default VerifyRejectionButton;
