import { api, getEndpoint } from "@/api";
import { INotification } from "@/types/notifications";

class Notifications {
  public static Create(payload) {
    return api.post(getEndpoint("notifications"), payload);
  }
  public static Update(item: INotification, payload) {
    return api.put(
      getEndpoint("singleNotification", {
        id: item.id,
      }),
      payload
    );
  }
}
export default Notifications;
