// Built-in import
import * as React from "react";

// External import
import { Button, Stack, Typography, alpha } from "@mui/material";

import { AddEventTypes } from "./event-cards-listing";
import { EarlySettlement } from "./edit/early-settlement";

import { PerformanceReport } from "./add/performance-report";
import { ProfitDeposit } from "./add/profit-deposit";
import { useGlobalContext } from "@/context/global-context";
import { useTranslation } from "react-i18next";
import { EditPerformanceReport } from "./edit/performance-report";
import { EditProfitDeposit } from "./edit/profit-deposit";

export interface IEventCardItem {
  icon: React.ReactNode;
  title: string;
  description: string;
  type: AddEventTypes;
}
interface IAddEventCard {
  item: IEventCardItem;
  propertyId?: string;
  onFetchAllEvents?: () => void;
}

const AddEventCard: React.FC<IAddEventCard> = ({
  item,
  propertyId,
  onFetchAllEvents,
}) => {
  // Getting props as item

  const { icon, title, description } = item;
  const { closeModal, showModal } = useGlobalContext();
  // Getting translation object
  const { t } = useTranslation(["common"]);

  const changeStepHandler = () => {
    switch (item.type) {
      case AddEventTypes.PerformanceReport:
        closeModal();
        showModal(
          propertyId ? (
            <EditPerformanceReport
              projectId={propertyId}
              onFetchAllEvents={onFetchAllEvents}
            />
          ) : (
            <PerformanceReport />
          ),
          { width: ["100%", , "fit-content"] }
        );
        break;
      case AddEventTypes.ProfitDeposit:
        closeModal();
        showModal(
          propertyId ? (
            <EditProfitDeposit
              projectId={propertyId}
              onFetchAllEvents={onFetchAllEvents}
            />
          ) : (
            <ProfitDeposit />
          ),
          {
            width: ["100%", , "fit-content"],
          }
        );
        break;
      case AddEventTypes.EarlySettlement:
        closeModal();
        showModal(
          <EarlySettlement
            projectId={propertyId}
            onFetchAllEvents={onFetchAllEvents}
          />,
          {
            width: ["100%", , "fit-content"],
          }
        );
        break;
      default:
        break;
    }
  };

  return (
    <Stack
      sx={{
        border: "1px solid",
        borderColor: (t) => t.palette.grey[300],
        p: 2,
        borderRadius: "10px",
        maxWidth: "270px",
        boxShadow: (t) => `0 0 10px ${alpha(t.palette.common.black, 0.07)}`,
      }}
      spacing={1.5}
    >
      <Stack
        direction="row"
        spacing={1}
        sx={{ color: (t) => t.palette.primary.main }}
        alignItems="center"
      >
        {icon}
        <Typography variant="subtitleBold" color="primary.main">
          {title}
        </Typography>
      </Stack>
      <Typography variant="smallMedium" color="text.secondary">
        {description}
      </Typography>
      <Button fullWidth variant="contained" onClick={changeStepHandler}>
        {t("common:create_event")}
      </Button>
    </Stack>
  );
};

export default AddEventCard;
