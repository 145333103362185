// Internal import
import { TitleWithDescription, UserInformation } from "@/components/shared"

import { FC } from "react"
import { IProperty } from "@/types/properties"
// External import
import { Stack } from "@mui/material"
import { useTranslation } from "react-i18next"

interface IPropertiesCardDescription {
  item: IProperty
}

const PropertiesCardDescription: FC<IPropertiesCardDescription> = ({
  item,
}) => {
  // const { avatar, name, role, description } = item;

  const { t } = useTranslation(["common"])

  let roleTitle

  if (item?.owner?.type)
    roleTitle = `${t("common:funder")} ${t(
      `common:${item.owner?.type?.toLocaleLowerCase()}`
    )}`

  return (
    <Stack flexGrow={1} spacing={1.5}>
      {item?.owner && (
        <UserInformation
          user={{ name: item?.owner_name || "" }}
          direction="column"
          sxProps={{
            "& .MuiAvatar-root": {
              width: "53px",
              height: "53px",
            },
          }}
          title={roleTitle}
          variantValue={"extraSmallMedium"}
        />
      )}

      <TitleWithDescription
        title={item?.title || ""}
        headerVariant="subtitleBold"
        colorDescription={"text.secondary"}
        color="black"
        description={
          item.description ? `${item?.description?.substring(0, 100)} ...` : ""
        }
        descriptionVariant={"bodyRegular"}
        alignment="justify"
        spacing={1.5}
      />
    </Stack>
  )
}

export default PropertiesCardDescription
