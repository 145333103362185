//Built-in import
import * as React from "react";
//External import
import { IconButton } from "@mui/material";
import SendRoundedIcon from "@mui/icons-material/SendRounded";

interface IChatboxInputButtonTextProps {
  addMessage: Function;
}

const ChatboxInputButtonText: React.FunctionComponent<
  IChatboxInputButtonTextProps
> = ({ addMessage }) => {
  return (
    <IconButton onClick={() => addMessage()}>
      <SendRoundedIcon />
    </IconButton>
  );
};

export default ChatboxInputButtonText;
