// Built-in import
import * as React from "react";

import { IServerProperty } from "@/store/slices/property";
// External import
import { Stack, Typography } from "@mui/material";

// Internal import
import Tag from "@/components/shared/tag";
import { limitChar } from "@/utils";
import { useTranslation } from "react-i18next";

interface ITitleCardProps {
  item: IServerProperty;
}

const TitleCard: React.FC<ITitleCardProps> = ({ item }) => {
  const { t } = useTranslation(["common"]);
  return (
    <Stack
      direction="row"
      justifyContent={"space-between"}
      alignItems="center"
      sx={{ minHeight: 60 }}
    >
      <Typography
        variant="subtitleBold"
        color="text.primary"
        sx={{ width: `calc(100% - 120px)` }}
      >
        {limitChar(item.title, 50)}
      </Typography>
      <Tag
        title={t(`common:property_status_${item.status}`)}
        color={item.status}
      />
    </Stack>
  );
};

export default TitleCard;
