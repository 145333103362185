import * as React from "react"

import { Navigate } from "react-router-dom"
import { t } from "i18next"
import { toaster } from "./components/shared/toaster"

class ErrorBoundary extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo)
  }

  render() {
    if (this.state?.hasError) {
      // You can render any custom fallback UI
      toaster.warning(t("something_went_wrong"))
      //   window.location.href = "/dashboard"
      return null
    }

    return this.props.children
  }
}

export default ErrorBoundary
