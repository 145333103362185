//Built-in import
import * as React from "react";
//External import
import { IconButton, Stack, TextField } from "@mui/material";
//Internal import
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

const ProtectedPasswordInput: React.FunctionComponent<any> = (props) => {
  //Is show state password view
  const [isShowPassword, setIsShowPassword] = React.useState(false);

  //Toggle component for viewing password
  const toggleShowPassword = () => setIsShowPassword((crt) => !crt);

  return (
    <Stack sx={{ position: "relative" }}>
      <TextField {...props} type={isShowPassword ? "text" : "password"} />
      {props?.value && (
        <IconButton
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
          }}
          onClick={toggleShowPassword}
        >
          {isShowPassword ? (
            <VisibilityOffOutlinedIcon />
          ) : (
            <VisibilityOutlinedIcon />
          )}
        </IconButton>
      )}
    </Stack>
  );
};

export default ProtectedPasswordInput;
