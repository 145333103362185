// Built-in import
import * as React from "react"

import FutureCardContent from "./default/future"
// External import
import { Stack } from "@mui/material"
// Internal import
import { TitleWithValue } from "@/components/shared"
import { useTranslation } from "react-i18next"

interface IChildrenProjectRegistration {
  item: any
}

const ChildrenProjectRegistration: React.FC<IChildrenProjectRegistration> = ({
  item
}) => {
  // Getting props as item
  const { date } = item
  // Getting translation object
  const { t } = useTranslation(["common"])

  if (item.expires_at) return <FutureCardContent item={item} />
  if (item.revenue_percent) {
    return (
      <TitleWithValue
        title={t("common:depsoited_profit")}
        value={`${item.revenue_percent}%`}
        hasDoubleDot={false}
        justifyContent="space-between"
        colorTitle={"text.secondary"}
        variantTitle="bodyBold"
        variantValue={"bodyMedium"}
        alignItems="flex-start"
      />
    )
  }

  return (
    <Stack direction="row" pb={1}>
      <TitleWithValue
        title={item.title || "-"}
        value={date}
        justifyContent="space-between"
        hasDoubleDot={false}
        colorTitle={"text.secondary"}
        variantTitle="bodyBold"
        variantValue={"bodyMedium"}
      />
    </Stack>
  )
}

export default ChildrenProjectRegistration
