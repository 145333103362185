import * as React from "react";
import {
  Stack,
  Box,
  Typography,
  Theme,
  SxProps,
  useTheme,
  useMediaQuery,
} from "@mui/material";

interface IWizardStepSliceProps {
  title: string;
  isActive?: boolean;
}

export const WizardStepSlice: React.FunctionComponent<
  IWizardStepSliceProps
> = ({ title, isActive = false }) => {
  //style for active step
  const activeStyles: SxProps<Theme> = {
    border: "none",
    bgcolor: (t) => t.palette.primary.main,
  };
  // Getting mui theme
  const theme = useTheme<Theme>();
  //Checking viewport environment
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack spacing={1} alignItems="center">
      {!isMobile && (
        <Typography variant="bodyLight" color="primary">
          {title}
        </Typography>
      )}

      <Box
        sx={{
          width: "100%",
          height: 16,
          borderRadius: "22px",
          border: "1px solid",
          borderColor: (t) => t.palette.primary.main,
          ...((isActive && activeStyles) || {}),
        }}
      />
    </Stack>
  );
};
