import { StoreType } from "@/store/store"
import { createSelector } from "reselect"

const propertyStore = (state: StoreType) => state.property

export const propertySelector = createSelector(
  propertyStore,
  (property) => property
)
export const mediaSelector = createSelector(
  propertyStore,
  (property) => property.media
)
export const coverSelector = createSelector(
  propertyStore,
  (property) => property.background
)
