import * as React from "react"

import { Stack, Typography } from "@mui/material"

import { IEvent } from "@/types/event"
import { TitleWithValue } from "@/components/shared"
import { humanDate } from "@/utils"
import { useTranslation } from "react-i18next"

interface IFutureCardContentProps {
  item: IEvent
}

const FutureCardContent: React.FunctionComponent<IFutureCardContentProps> = ({
  item
}) => {
  const { t } = useTranslation(["common"])

  if (!item.expires_at) return null

  if (item.type === "Report")
    return (
      <Typography>
        {t("common:report_in_future", {
          date: humanDate(item.expires_at),
          type:
            item.type !== "Report"
              ? t(`common:${(item.type as any)?.toLowerCase()}`)
              : ""
        })}
      </Typography>
    )

  return (
    <Stack>
      {item.revenue_percent && (
        <TitleWithValue
          title={t("common:expected_profit")}
          value={`${item.revenue_percent}%`}
          hasDoubleDot={false}
          justifyContent="space-between"
          colorTitle={"text.secondary"}
          variantTitle="bodyBold"
          variantValue={"bodyMedium"}
          alignItems="flex-start"
        />
      )}
    </Stack>
  )
}

export default FutureCardContent
