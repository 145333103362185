import * as React from "react";
import {
  alpha,
  Box,
  Paper,
  Stack,
  SxProps,
  Typography,
  useTheme,
} from "@mui/material";

interface IContentWrapperWithCustomHeadingProps {
  title: string;
  children: React.ReactNode;
  headingColor?: string;
  meta?: React.ReactNode;
  sxProps?: SxProps;
}

const ContentWrapperWithCustomHeading: React.FunctionComponent<
  IContentWrapperWithCustomHeadingProps
> = ({ title, headingColor = "", meta, sxProps, children }) => {
  const theme = useTheme();

  headingColor = headingColor == "" ? theme.palette.grey[200] : headingColor;
  return (
    <Paper
      sx={{
        width: "100%",
        p: 0,
        border: "1px solid",
        overflow: "hidden",
        borderColor: (t) => alpha(t.palette.common.black, 0.2),
        ...sxProps,
      }}
    >
      <Stack
        direction={"row"}
        sx={{
          p: 2,
          px: 3,
          alignItems: "center",
          bgcolor: (t) => headingColor,
          borderBottom: "1px solid",
          borderBottomColor: (t) => alpha(t.palette.common.black, 0.2),
        }}
        justifyContent="space-between"
        className="heading-content-wrapper"
      >
        <Typography variant="subtitleMedium" color={"common.black"}>
          {title}
        </Typography>
        {meta}
      </Stack>
      <Box sx={{ p: 2 }}>{children}</Box>
    </Paper>
  );
};

export default ContentWrapperWithCustomHeading;
