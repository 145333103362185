import "react-modern-calendar-datepicker/lib/DatePicker.css";

// Built-in import
import * as React from "react";
// External import
import * as Yup from "yup";

import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
// Internal import
import { MyDatePicker, TitleWithValue, Uploader } from "@/components/shared";
import { LoadingButton } from "@mui/lab";
import { Report } from "@/services";
import { toaster } from "@/components/shared/toaster";
// import { imagesFormCreateNewReportData } from "./data"
import { useFormik } from "formik";
import { useGlobalContext } from "@/context/global-context";
import { useTranslation } from "react-i18next";
import { useUploader } from "@/hook";
import GalleryUploader from "@/components/shared/gallery-uploader";

interface IEditPerformanceReportForm {
  projectId: string;
  onFetchAllEvents?: () => void;
}

export const EditPerformanceReportForm: React.FC<
  IEditPerformanceReportForm
> = ({ projectId, onFetchAllEvents }) => {
  const [typeToAdd, setTypeToAdd] = React.useState(0);

  const [expiresAt, setExpriesAt] = React.useState<null | number>(null);

  const [files, setFiles] = React.useState<File[]>([]);

  //get translation object
  const { t } = useTranslation();

  // modal
  const { closeModal } = useGlobalContext();

  // formik
  const {
    isSubmitting,
    touched,
    errors,
    getFieldProps,
    handleSubmit,
    setSubmitting,
  } = useFormik({
    initialValues: {
      title: "",
      description: "",
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required(t("common:this_field_is_required")),
      description: Yup.string().required(t("common:this_field_is_required")),
    }),
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true);
      try {
        await Report.Create(projectId, "Report", values, files);
        toaster.success(t("common:your_event_has_been_added"));
        onFetchAllEvents();
        closeModal();
      } catch (e) {
        toaster.error(e);
      } finally {
        actions.setSubmitting(false);
      }
    },
  });

  const handleAddReportInFuture = async () => {
    if (!expiresAt) {
      toaster.warning(t("common:please_select_the_date"));
      return;
    }
    setSubmitting(true);
    try {
      await Report.Create(projectId, "Report", {
        expires_at: expiresAt,
      });
      toaster.success(t("common:your_event_has_been_added"));
      onFetchAllEvents();
      closeModal();
    } catch (e) {
      toaster.error(e);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Stack
      minHeight={{ xs: "calc(100vh -  100px)", md: 400 }}
      px={2}
      justifyContent={"space-between"}
      alignItems="start"
      spacing={3}
      component="form"
      width={{ xs: "100%", md: "800px", lg: "1000px" }}
      onSubmit={handleSubmit}
      sx={{
        height: "100%",
        maxHeight: "700px",
        overflowY: "scroll",
      }}
    >
      <FormControl fullWidth>
        <RadioGroup
          defaultValue={typeToAdd}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setTypeToAdd(+e.target.value)
          }
        >
          <Grid container spacing={3} flexWrap="wrap-reverse">
            <Grid item xs={12} md={6}>
              <Stack spacing={2}>
                {/* radio button */}
                <FormControlLabel
                  value={0}
                  control={<Radio />}
                  label={t("common:register_new_report")}
                />
                <Stack spacing={3} width={"100%"}>
                  {/* title report */}
                  <TitleWithValue
                    title={t("common:title_report")}
                    hasDoubleDot={false}
                    value={
                      <TextField
                        fullWidth
                        size="small"
                        {...getFieldProps("title")}
                        error={Boolean(touched.title && errors.title)}
                        helperText={touched.title && errors.title}
                      />
                    }
                    direction="column"
                    alignItems={"flex-start"}
                  />
                  {/* description report */}
                  <TitleWithValue
                    title={t("common:description_report")}
                    hasDoubleDot={false}
                    value={
                      <TextField
                        fullWidth
                        multiline
                        rows={7}
                        size="small"
                        {...getFieldProps("description")}
                        error={Boolean(
                          touched.description && errors.description
                        )}
                        helperText={touched.description && errors.description}
                      />
                    }
                    direction="column"
                    alignItems={"flex-start"}
                  />
                  <GalleryUploader onChange={(values) => setFiles(values)} />
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack
                sx={{
                  borderLeft: ["none", , "1px solid"],
                  borderColor: (t) => ["none", , t.palette.grey[300]],
                  height: "100%",
                  pl: [0, , 3],
                }}
                spacing={2}
              >
                {/* radio button */}
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label={t("common:anticipate_future_reports")}
                />
                {/* date picker */}
                <TitleWithValue
                  title={t("common:time_of_publication_of_report")}
                  hasDoubleDot={false}
                  value={
                    <Stack width="100%">
                      <MyDatePicker
                        onChangeDate={(date) => {
                          setExpriesAt(date?.getTime() / 1000);
                        }}
                        title={t("common:date_you_expect_to_report")}
                      />
                    </Stack>
                  }
                  direction="column"
                  alignItems={"flex-start"}
                />
              </Stack>
            </Grid>
          </Grid>
        </RadioGroup>
      </FormControl>

      {/* submit */}
      {typeToAdd === 0 && (
        <Stack width="100%" alignItems={"flex-end"}>
          <LoadingButton
            loading={isSubmitting}
            variant="contained"
            type="submit"
          >
            {t("common:record_report")}
          </LoadingButton>
        </Stack>
      )}
      {typeToAdd === 1 && (
        <Stack width="100%" alignItems={"flex-end"}>
          <LoadingButton
            loading={isSubmitting}
            variant="contained"
            onClick={handleAddReportInFuture}
          >
            {t("common:record_report")}
          </LoadingButton>
        </Stack>
      )}
      {/* submit */}
    </Stack>
  );
};
