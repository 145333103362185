// Built-in import
import * as React from "react"

// External import
import { Typography } from "@mui/material"
import { limitChar } from "@/utils"

interface IDescriptionCard {
  description: string
}

const DescriptionCard: React.FC<IDescriptionCard> = ({ description }) => {
  return (
    <Typography
      variant="smallMedium"
      color="grey.600"
      lineHeight={"30px !important"}
      textAlign="justify"
      sx={{ minHeight: 60 }}
    >
      {description ? limitChar(description, 100) : "-"}
    </Typography>
  )
}

export default DescriptionCard
