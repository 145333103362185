//Built-in import
import * as React from "react";
//External import
import { Stack, alpha } from "@mui/material";
//Internal import
import MessageTextField from "./text";

interface IMessageInputHandlerProps {
  addMessage: Function;
  messageText: string;
  handleSetMessageText: Function;
}

const MessageInputHandler: React.FunctionComponent<
  IMessageInputHandlerProps
> = ({ addMessage, messageText, handleSetMessageText }) => {
  return (
    <Stack
      sx={{
        borderTop: 1,
        borderColor: (t) => alpha(t.palette.common.black, 0.12),
      }}
    >
      <MessageTextField
        val={messageText}
        handleChange={handleSetMessageText}
        addMessage={addMessage}
      />
    </Stack>
  );
};

export default React.memo(MessageInputHandler);
