// Built-in import
import * as React from "react";

import { AddEventCardsListing } from "./event-cards-listing";
// Internal import
import ModalContentWrapper from "@/components/shared/modal/modal-content-wrapper";
import { useTranslation } from "react-i18next";

interface IAddEventModalContent {
  propertyId?: string;
  onFetchALlEvents?: () => void;
}

const AddEventModalContent: React.FC<IAddEventModalContent> = ({
  propertyId,
  onFetchALlEvents,
}) => {
  //getting translation object
  const { t } = useTranslation();
  return (
    <ModalContentWrapper
      titleVariant="headingBold"
      title={t("which_of_following_events_would_you_like_to_add")}
    >
      {
        <AddEventCardsListing
          {...(propertyId && { propertyId })}
          onFetchAllEvents={onFetchALlEvents}
        />
      }
    </ModalContentWrapper>
  );
};

export default AddEventModalContent;
