// Built-in import
import * as React from "react"

import Logo from "@/containers/page-wrapper/partials/header/logo"
// External import
import { Stack } from "@mui/material"

interface IPropertiesCardImage {
  img: string
}

const PropertiesCardImage: React.FC<IPropertiesCardImage> = ({ img }) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      minWidth="225px"
      minHeight="200px"
      width="225px"
      height="200px"
      sx={{
        borderRadius: "12px",
        boxShadow: "0px 0px 15px #99999960",
        overflow: "hidden",
        backgroundPosition: "center center",
        backgroundSize: "cover !important",
        background: `url("${img}")`
      }}
    >
      {!img && (
        <Stack sx={{ filter: `grayscale(1)` }}>
          <Logo />
        </Stack>
      )}
    </Stack>
  )
}

export default PropertiesCardImage
