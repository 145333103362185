//Built-in import
import * as React from "react";

//External import
import { Stack, SxProps, Theme, Typography } from "@mui/material";

//Internal import
import { AllVariants } from "@/mui/theme/declarations";

interface ITitleWithDescriptionProps {
  title: string | React.ReactNode;
  description?: string | number;
  //import AllVariant Types
  headerVariant?: AllVariants;
  descriptionVariant?: AllVariants;
  primaryColor?: string;
  secondaryColor?: string;
  colorDescription?: string;
  color?: string;
  alignment?:
    | { xs?: string; sm?: string; md?: string; lg?: string; xl?: string }
    | (
        | "center"
        | "right"
        | "left"
        | "justify"
        | ("left" | "right" | "center")[]
      );
  sxProps?: SxProps<Theme>;
  meta?: any;
  metaSpacing?: number;
  spacing?: number;
  headingType?: React.ElementType;
  descriptionType?: React.ElementType;
  direction?: "row" | "column";
  justifyContent?: "space-between" | "inherit";
}

const TitleWithDescription: React.FunctionComponent<
  ITitleWithDescriptionProps
> = ({
  title = "",
  description = "",
  headerVariant = "headingBold",
  descriptionVariant = "bodyMedium",
  primaryColor = "primary",
  secondaryColor = "text.secondary",
  color = "text.secondary",
  alignment = "left",
  sxProps = {},
  meta,
  metaSpacing = 2.5,
  spacing = 2,
  headingType = "h1",
  descriptionType = "p",
  direction = "column",
  justifyContent = "inherit",
}) => {
  return (
    <Stack
      spacing={spacing}
      sx={sxProps}
      direction={direction}
      justifyContent={justifyContent}
    >
      <Stack
        direction="row"
        spacing={metaSpacing}
        alignItems="center"
        justifyContent={alignment}
      >
        {(Boolean(meta) && <>{meta}</>) || null}
        <Typography
          variant={headerVariant}
          color={primaryColor}
          sx={{
            textAlign: alignment,
            textTransform: "capitalize",
          }}
          component={headingType}
        >
          {title}
        </Typography>
      </Stack>
      {Boolean(description) && (
        <Typography
          variant={descriptionVariant}
          component={descriptionType}
          color={secondaryColor}
          sx={{ textAlign: alignment }}
        >
          {description}
        </Typography>
      )}
    </Stack>
  );
};

export default TitleWithDescription;
