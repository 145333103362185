// Built-in import
import * as React from "react";

// External import
import { Grid, Stack, alpha, useTheme } from "@mui/material";

import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { IMedia } from "@/types/media";
import { IProperty } from "@/types/properties";
import PropertiesCardDescription from "./description";
import PropertiesCardDetails from "./details";
import PropertiesCardDetailsSuccess from "./details-success";
import PropertiesCardImage from "./image";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
// Internal import
import StructureCard from "@/components/shared/event-card/structure-card";
import { useMedia } from "@/hook";

interface ILandScapePropertyCard {
  item: IProperty;
}

const LandScapePropertyCard: React.FC<ILandScapePropertyCard> = ({ item }) => {
  // Getting mui theme
  const theme = useTheme();
  const background = useMedia(item.media, "background") as IMedia;
  const deafaultBg = useMedia(item.media, "default") as IMedia;
  const targetBg = background?.original_url || deafaultBg?.original_url
  // Getting props as item
  const { status } = item;
  // icon card
  let iconCard: any;
  // icon color card
  let iconColorCard: string;
  // color card
  let colorCard: string;
  // switch case
  switch (status) {
    case "waiting":
      iconCard = <QueryBuilderIcon />;
      iconColorCard = theme.palette.grey[700];
      colorCard = theme.palette.warning.light;
      break;
    case "published":
    case "success":
      iconCard = <CheckCircleOutlineOutlinedIcon />;
      iconColorCard = theme.palette.common.white;
      colorCard = theme.palette.success.light;
      break;
    case "error":
    case "suspended":
      iconCard = <HighlightOffIcon />;
      iconColorCard = theme.palette.common.white;
      colorCard = theme.palette.error.light;
      break;
    default:
      iconCard = <QueryBuilderIcon />;
      iconColorCard = theme.palette.common.black;
      colorCard = theme.palette.warning.light;
      break;
  }

  return (
    <StructureCard
      cardIcon={iconCard}
      cardIconBgcolor={colorCard}
      sxProps={{
        height: ["100%", , , "250px"],
        overflow: "hidden",
        border: "1px solid",
        borderColor: (t) => alpha(t.palette.common.black, 0.12),
      }}
    >
      <Stack
        px={2}
        py={{ xs: 5, lg: 0 }}
        width="100%"
        height="100%"
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Grid container spacing={5}>
          <Grid item xs={12} lg={7} xl={8}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 4, md: 2 }}
              alignItems="flex-start"
            >
              <PropertiesCardImage img={targetBg} />
              <PropertiesCardDescription item={item} />
            </Stack>
          </Grid>

          <Grid item xs={12} lg={5} xl={4}>
            <Stack>
              {status == "success" ? (
                <PropertiesCardDetailsSuccess item={item} />
              ) : (
                <PropertiesCardDetails item={item} />
              )}
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </StructureCard>
  );
};

export default LandScapePropertyCard;
