import * as React from "react";
import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AccordionSummaryPartial from "../partial";

interface ICustomAccordionSummaryProps {
  expanded: boolean;
  handleChange?: Function;
  children: React.ReactNode;
  placement?: string;
}

const CustomAccordionSummary: React.FunctionComponent<
  ICustomAccordionSummaryProps
> = ({ handleChange, expanded, children, placement }) => {
  const { t } = useTranslation();
  return (
    <AccordionSummaryPartial {...(placement && { placement })}>
      {children}
      <IconButton onClick={() => handleChange()}>
        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>
    </AccordionSummaryPartial>
  );
};

export default React.memo(CustomAccordionSummary);
