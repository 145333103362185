import { api, getEndpoint } from "@/api"

import { IResponseForSubmitEntity } from "@/api/types"
import { IUser } from "@/types/user"
import { getUrlWithQueryParams } from "@/utils"

class User {
  public static Get() {
    return api.get(
      getUrlWithQueryParams(getEndpoint("profile"), {
        expand: "media"
      })
    )
  }
  public static Create(data: object = {}) {
    return api.post<IResponseForSubmitEntity<IUser>>(getEndpoint("users"), data)
  }
  public static Update(
    user_id: string | number,
    token: string,
    data: object = {}
  ) {
    return api.put<IResponseForSubmitEntity<IUser>>(
      getEndpoint("singleUser", {
        id: user_id
      }),
      data,
      {
        headers: {
          [`Authorization`]: `Bearer ${token}`
        }
      }
    )
  }

  public static ResetPassword(token: string, password: string) {
    return api.put(
      getEndpoint("resetPassword"),
      {
        password,
        password_confirmation: password
      },
      {
        headers: {
          [`Authorization`]: `Bearer ${token}`
        }
      }
    )
  }
}
export default User
