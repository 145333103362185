import TitleWithValue from "@/components/shared/title-with-value";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { alpha, Stack, Button, Typography } from "@mui/material";
import { PerformanceReportActionButtons } from "../actions-button";
import { AddPerformanceReportTypes } from "../add-report";
import ForcastInputDate from "./date-input";

interface IForcastDate {
  changeStepHandler: (step: AddPerformanceReportTypes) => void;
}

const ForcastDate: React.FunctionComponent<IForcastDate> = ({
  changeStepHandler,
}) => {
  //getting translation object
  const { t } = useTranslation();

  const [value, setValue] = React.useState("");

  return (
    <React.Fragment>
      <Stack spacing={2} width={"100%"}>
        <Typography variant="smallBold">
          {t(
            "common:enter_times_you_expect_to_report_to_investors_during_project_in_this_section"
          )}
        </Typography>
        <Stack
          p={3}
          sx={{
            border: "1px solid",
            borderColor: (t) => `${alpha(t.palette.common.black, 0.2)}`,
            borderRadius: 2,
            width: "600px",
          }}
        >
          <Stack direction="row" spacing={1} width={"100%"}>
            <TitleWithValue
              direction={"column"}
              spacing={1}
              variantTitle={"smallRegular"}
              hasDoubleDot={false}
              alignItems={"flex-start"}
              title={t("expected_report_presentation_date")}
              value={
                <ForcastInputDate
                  onChange={(value: string) => setValue(value)}
                />
              }
            />
          </Stack>

          <Stack direction={"row"} mt={2}>
            <Button
              disabled={Boolean(!value.length)}
              variant="contained"
              onClick={() =>
                changeStepHandler(AddPerformanceReportTypes.SaveEvents)
              }
            >
              {t("submit_report_event")}
            </Button>
          </Stack>
        </Stack>
      </Stack>

      <PerformanceReportActionButtons
        handlePrevStep={() =>
          changeStepHandler(AddPerformanceReportTypes.AddReport)
        }
      />
    </React.Fragment>
  );
};

export default ForcastDate;
