// Built-in import
import * as React from "react";

// External import
import { Stack, SxProps, Theme } from "@mui/material";

interface IStructureCard {
  cardBgColor?: string;
  cardIcon: React.ReactElement;
  cardIconColor?: string;
  children?: React.ReactNode;
  cardIconBgcolor?: string;
  sxProps?: SxProps<Theme>;
}

const StructureCard: React.FC<IStructureCard> = ({
  cardBgColor,
  cardIcon,
  cardIconBgcolor,
  cardIconColor,
  sxProps,
  children,
}) => {
  return (
    <Stack
      direction={{ xs: "column", lg: "row" }}
      sx={{
        bgcolor: cardBgColor,
        minHeight: "100%",
        height: ["fit-content", "100%"],
        maxWidth: "100%",
        width: "100%",
        borderRadius: "10px",
        overflowX: "hidden",
        border: 1,
        borderColor: (t) => t.palette.divider,
        mb: 2,
        ...sxProps,
      }}
      alignItems="center"
    >
      {cardIcon && (
        <Stack
          sx={{
            minWidth: "40px",
            bgcolor: cardIconBgcolor,
            width: ["100%", , , "40px"],
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            color: "white",
            py: [0.5, 0],
            [`& svg`]: {
              color: (t) =>
                cardIconColor ? cardIconColor : t.palette.common.white,
            },
          }}
        >
          {cardIcon}
        </Stack>
      )}

      {children}
    </Stack>
  );
};

export default StructureCard;
