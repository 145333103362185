import { api, getEndpoint } from "@/api"

class Role {
  public static Assign(role_id: string | number, data: object = {}) {
    return api.post(
      getEndpoint("rolePartial", {
        role_id: role_id,
        partial: "assign"
      }),
      data
    )
  }
}
export default Role
