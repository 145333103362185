import * as React from "react";

import ModalContentWrapper from "@/components/shared/modal/modal-content-wrapper";
import { ProfitDepositForm } from "./form";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

interface IEditProfitDeposit {
  projectId: string;
  onFetchAllEvents?: () => void;
}

export const EditProfitDeposit: React.FC<IEditProfitDeposit> = ({
  projectId,
  onFetchAllEvents,
}) => {
  //Getting transaltion object
  const { t } = useTranslation();

  return (
    <ModalContentWrapper
      title={t("creating_profit_deposit_event")}
      titleVariant="subtitleBold"
    >
      <ProfitDepositForm
        projectId={projectId}
        onFetchAllEvents={onFetchAllEvents}
      />
    </ModalContentWrapper>
  );
};
