import * as React from "react";

import { Stack, Typography } from "@mui/material";

import { t } from "i18next";

interface INoItemsWasFoundProps {
  title?: string;
  icon?: React.ReactNode;
}

const NoItemsWasFound: React.FunctionComponent<INoItemsWasFoundProps> = ({
  title = t("no_items_was_found"),
  icon,
}) => {
  return (
    <Stack
      sx={{ py: 4 }}
      alignItems="center"
      justifyContent="center"
      height={"auto"}
      spacing={2}
    >
      {Boolean(icon) && icon}
      <Typography variant="bodyBold">{title}</Typography>
    </Stack>
  );
};

export default NoItemsWasFound;
