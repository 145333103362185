// Built-in import
import * as React from "react"

// External import
import { Slider } from "@mui/material"

interface ISliderPrice {
  value: number[]
  marks?: any[]
  min?: number
  max?: number
  scale?: any
  step?: number
  valueLabelFormat?: any
  onChange?: (value: number | number[]) => void
}

const SliderPrice: React.FC<ISliderPrice> = ({
  value,
  marks,
  min = 1,
  step = 5,
  max = 100,
  scale,
  valueLabelFormat,
  onChange
}) => {
  const [valueSlider, setValueSlider] = React.useState<number[]>(value)
  const handleChange = (event: Event, newValue: number | number[]) => {
    setValueSlider(newValue as number[])

    if (onChange) onChange(newValue)
  }

  React.useEffect(() => {
    setValueSlider(value)
  }, [value])

  return (
    <Slider
      value={valueSlider}
      scale={scale}
      step={step}
      valueLabelFormat={valueLabelFormat}
      onChange={handleChange}
      valueLabelDisplay="auto"
      marks={marks}
      min={min}
      max={max}
    />
  )
}

export default SliderPrice
