// Built-in import
import * as React from "react";

// External import
import { SxProps, useTheme } from "@mui/material";

import ChildrenDefaultEvent from "./childrens/default";
import ChildrenDeleteEvent from "./childrens/delete-event";
import ChildrenProjectRegistration from "./childrens/project-registration";
import ChildrenStructureTitle from "./children-structure";
import ChildrenWaitingPlan from "./childrens/waiting-plan";
import DollarIcon from "@/components/icons/dollar-icon";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { IEvent } from "@/types/event";
import LaunchIcon from "@mui/icons-material/Launch";
import StructureCard from "./structure-card";
// Internal import
import { SuccessIcon } from "@/components/icons";
import { humanDate } from "@/utils";
import { useTranslation } from "react-i18next";

interface IEventCard {
  item?: IEvent;
  sxProps?: SxProps;
}

const EventCard: React.FC<IEventCard> = ({ item, sxProps }) => {
  // Getting translation object
  const { t } = useTranslation(["common"]);
  // getting status as item
  const { status } = item;
  // Getting theme mui
  const theme = useTheme();
  // icon card
  let cardIcon: React.ReactElement;
  // bg color
  let cardBgColor: string;
  // meta card
  let childrenCard: React.ReactNode;
  // switch case status
  switch (status) {
    case "Accepted":
      childrenCard = (
        <ChildrenStructureTitle
          event={item}
          cardTitle={`${t("common:date")}:`}
          cardMeta={humanDate(item.created_at, "jYYYY/jM/jD HH:mm")}
        >
          <ChildrenProjectRegistration item={item} />
        </ChildrenStructureTitle>
      );
      break;
    case "Pending":
      childrenCard = (
        <ChildrenStructureTitle
          event={item}
          cardTitle={`${t("common:date_application")}:`}
          cardMeta={humanDate(item.created_at, "jYYYY-jM-jD HH:mm:ss")}
        >
          <ChildrenWaitingPlan item={item} />
        </ChildrenStructureTitle>
      );
      break;
    case "Rejected":
      childrenCard = (
        <ChildrenStructureTitle
          event={item}
          cardTitle={`${t("common:event_rejection_date")}:`}
          cardMeta={humanDate(item.created_at, "jYYYY/jM/jD HH:mm")}
        >
          <ChildrenDefaultEvent item={item} />
        </ChildrenStructureTitle>
      );
      break;
    case "Deleted":
      childrenCard = (
        <ChildrenStructureTitle
          event={item}
          cardTitle={`${t("common:event_rejection_date")}:`}
          cardMeta={humanDate(item.created_at, "jYYYY/jM/jD HH:mm")}
        >
          <ChildrenDefaultEvent item={item} />
        </ChildrenStructureTitle>
      );
      break;
    default:
      cardBgColor = theme.palette.grey[400];
      childrenCard = (
        <ChildrenStructureTitle
          event={item}
          cardTitle={t("common:date")}
          cardMeta={humanDate(item.created_at)}
        >
          <ChildrenDefaultEvent item={item} />
        </ChildrenStructureTitle>
      );
      break;
  }

  if (item.type === "News") {
    return (
      <StructureCard
        cardBgColor={cardBgColor}
        cardIcon={cardIcon}
        sxProps={sxProps}
      >
        {childrenCard}
      </StructureCard>
    );
  } else {
    let userCardIcon = <></>;
    let useCardBgColor = theme.palette.info.light;
    switch (item.type) {
      case "Finance":
        userCardIcon = <DollarIcon color={theme.palette.common.white} />;
        break;
      case "Report":
        userCardIcon = <LaunchIcon />;
        useCardBgColor = "rgb(135, 42, 178)";
        break;
      default:
        userCardIcon = <LaunchIcon />;
    }
    return (
      <StructureCard
        cardBgColor={useCardBgColor}
        cardIcon={userCardIcon}
        sxProps={sxProps}
      >
        {childrenCard}
      </StructureCard>
    );
  }
};

export default EventCard;
