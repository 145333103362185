// Built-in import
import * as React from "react"

// External Import
import { Box, Button, Grid } from "@mui/material"
import { api, getEndpoint } from "@/api"

import FileIcon from "@/components/icons/file-icon"
import { LoadingButton } from "@mui/lab"
// Internal Import
import { SearchBox } from "@/components/shared"
import { getUrlWithQueryParams } from "@/utils"
import { toaster } from "../toaster"
import { useTranslation } from "react-i18next"

interface ISearchBoxWithExportProps {
  handleChangeQueries: (key: string, val: any) => void
  queries?: any
}

const SearchBoxWithExport: React.FunctionComponent<
  ISearchBoxWithExportProps
> = ({ handleChangeQueries, queries = {} }) => {
  // Getting Translation object
  const { t } = useTranslation(["common"])

  //set loading for export excel btn
  const [loading, setLoading] = React.useState(false)
  const [s, setSearch] = React.useState("")

  const handleChange = (val) => {
    setSearch(val)
    if (handleChangeQueries) handleChangeQueries("s", val)
  }

  const handleExportExcel = () => {
    setLoading(true)
    api({
      method: "GET",
      url: getUrlWithQueryParams(getEndpoint("exportExcelTranasction"), queries)
    })
      .then((res) => {
        window.open(res.data.data.item)
        setLoading(false)
      })
      .catch((err) => {
        toaster.error(err.response.data)
        setLoading(false)
      })
  }

  return (
    <Box>
      <Grid container spacing={2} alignItems="center">
        <Grid item lg={9.5} md={12} xs={12}>
          <SearchBox
            sxProps={{
              my: 0
            }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item lg={2.5} md={12} xs={12}>
          <LoadingButton
            startIcon={<FileIcon color="#E29314" />}
            variant="outlined"
            loading={loading}
            fullWidth
            onClick={handleExportExcel}
          >
            {t("common:get_table_output")}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SearchBoxWithExport
