// Built-in import
import * as React from "react";
import * as Yup from "yup";

// External import
import {
  Divider,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

// Internal import
import { Radios, TitleWithElement } from "@/components/shared";

import { AddPropertyStepTypes } from "@/types/properties";
import { AddPropertyStepsActions } from "../../actions";
import { dataRadios } from "./data";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Project } from "@/services";
import { toaster } from "@/components/shared/toaster";
import routes from "@/navigation/routes";
import { actionChangeProperty, IServerProperty } from "@/store/slices/property";

interface IAdditionalInformation {
  property: IServerProperty;
  isEdit?: boolean;
}

const AdditionalInformation: React.FC<IAdditionalInformation> = ({
  property,
  isEdit = false,
}) => {
  const navigator = useNavigate();
  // Getting translation object
  const { t } = useTranslation(["common"]);

  //Getting dispatch
  const dispatcher = useDispatch();

  // Formik
  const { handleSubmit, getFieldProps, values, isSubmitting } = useFormik({
    initialValues: {
      finance_statement: property?.finance_statement || "",
      company_statement: property?.company_statement || "",
      market_statement: property?.market_statement || "",
      advantage_statement: property?.advantage_statement || "",
    },

    onSubmit: async (values, actions) => {
      actions.setSubmitting(true);
      try {
        if (isEdit) {
          const res = await Project.Update(property.id, {
            ...property,
            ...values,
          });
          toaster.success(res.data.message);
          dispatcher(actionChangeProperty(res.data.data.item));
        } else {
          const res = await Project.Create(property);
          toaster.success(res.data.message);
          dispatcher(actionChangeProperty(res.data.data.item));
        }
        navigator(
          isEdit
            ? `/${routes.properties.index}`
            : `?type=${AddPropertyStepTypes.ExecutiveCalender}`
        );
        // showModal(<SuccessModalExecutive />);
      } catch (e) {
        toaster.error(e);
      } finally {
        actions.setSubmitting(false);
      }
    },
  });

  return (
    <Stack component="form" onSubmit={handleSubmit}>
      <Paper
        sx={{
          my: 1,
          p: [2, , 4],
          py: [4, , 4],
          border: "1px solid",
          borderRadius: "12px",
          borderColor: (t) => t.palette.grey[300],
        }}
      >
        <Stack spacing={8}>
          <Stack direction={"column"} spacing={2}>
            {/* Further information */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={12} lg={6}>
                <Stack>
                  <TitleWithElement
                    spacing={1}
                    title={t("common:financial_situation") + ":"}
                    element={
                      <TextField
                        rows={10}
                        value={values.finance_statement}
                        multiline
                        placeholder={t(
                          "common:enter_description_of_your_situation"
                        )}
                        {...getFieldProps("finance_statement")}
                      />
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={12} lg={6}>
                <Stack>
                  <TitleWithElement
                    spacing={1}
                    title={t("common:about_company") + ":"}
                    element={
                      <TextField
                        rows={10}
                        multiline
                        value={values.company_statement}
                        placeholder={t(
                          "common:provide_explanations_about_your_company_to_investors"
                        )}
                        {...getFieldProps("company_statement")}
                      />
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={12} lg={6}>
                <Stack>
                  <TitleWithElement
                    spacing={1}
                    title={t("common:target_market") + ":"}
                    element={
                      <TextField
                        value={values.market_statement}
                        rows={10}
                        multiline
                        placeholder={t("common:explain_your_target_market")}
                        {...getFieldProps("market_statement")}
                      />
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={12} lg={6}>
                <Stack>
                  <TitleWithElement
                    spacing={1}
                    title={t("common:competitive_advantage") + ":"}
                    element={
                      <TextField
                        rows={10}
                        value={values.advantage_statement}
                        multiline
                        placeholder={t(
                          "common:what_competitive_advantage_your_plan_market"
                        )}
                        {...getFieldProps("advantage_statement")}
                      />
                    }
                  />
                </Stack>
              </Grid>
            </Grid>
            {/* Further information */}
            <Divider />
            {/* Further information */}
            <Stack spacing={0.5}>
              <Typography variant="bodyBold" color="text.primary">
                {t("common:available_documents")}:
              </Typography>
              <Radios items={dataRadios} defaultValue="0" />
            </Stack>
          </Stack>
          <AddPropertyStepsActions
            isEdit={isEdit}
            loading={isSubmitting}
            nextStepTitle={
              isEdit ? t("common:save_changes") : t("common:send_property")
            }
            handlePrevStep={() =>
              navigator(`?type=${AddPropertyStepTypes.CapitalizationDetails}`)
            }
          />
        </Stack>
      </Paper>
    </Stack>
  );
};

export default AdditionalInformation;
