import { authSlice, propertySlice, settingsSlices } from "../slices"

import { combineReducers } from "redux"

const rootReducer = combineReducers({
  auth: authSlice,
  setting: settingsSlices,
  property: propertySlice
})

export default rootReducer
