import * as React from "react"

import { IIconType } from "@/types/icon"

interface MessageIconProps extends IIconType {}

const MessageIcon: React.FunctionComponent<MessageIconProps> = ({
  color = "#6E6B7B",
  width = 36,
  height = 36
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M30 4.5H6C3.45 4.5 1.5 6.45 1.5 9V27C1.5 29.55 3.45 31.5 6 31.5H30C32.55 31.5 34.5 29.55 34.5 27V9C34.5 6.45 32.55 4.5 30 4.5ZM6 7.5H30C30.6 7.5 31.05 7.8 31.35 8.4L18 17.7L4.65 8.4C4.95 7.8 5.4 7.5 6 7.5ZM4.5 27C4.5 27.9 5.1 28.5 6 28.5H30C30.9 28.5 31.5 27.9 31.5 27V11.85L18.9 20.7C18.6 20.85 18.3 21 18 21C17.7 21 17.4 20.85 17.1 20.7L4.5 11.85V27Z"
        fill="black"
      />
      <mask
        id="mask0_21_647"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="1"
        y="4"
        width="34"
        height="28"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M30 4.5H6C3.45 4.5 1.5 6.45 1.5 9V27C1.5 29.55 3.45 31.5 6 31.5H30C32.55 31.5 34.5 29.55 34.5 27V9C34.5 6.45 32.55 4.5 30 4.5ZM6 7.5H30C30.6 7.5 31.05 7.8 31.35 8.4L18 17.7L4.65 8.4C4.95 7.8 5.4 7.5 6 7.5ZM4.5 27C4.5 27.9 5.1 28.5 6 28.5H30C30.9 28.5 31.5 27.9 31.5 27V11.85L18.9 20.7C18.6 20.85 18.3 21 18 21C17.7 21 17.4 20.85 17.1 20.7L4.5 11.85V27Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_21_647)">
        <rect width={width} height={height} fill={color} />
      </g>
    </svg>
  )
}

export default MessageIcon
