import { api, getEndpoint } from "@/api";

class Categories {
  public static AddCategory(name: string, type: string) {
    return api.post(getEndpoint("categories", { group_type: type }), {
      categories: [{ name }],
    });
  }
  public static DeleteCategory(id: number, type: string) {
    return api.delete(
      getEndpoint("singleCategory", { group_type: type, category_id: id })
    );
  }
  public static UpdateCategory(name: string, id: number, type: string) {
    return api.put(
      getEndpoint("singleCategory", { group_type: type, category_id: id }),
      { name }
    );
  }
}

export default Categories;
