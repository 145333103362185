// Built-in import
import * as React from "react"

// External Import
import { Stack, SxProps, Theme, alpha } from "@mui/material"

import FinancialTableFilter from "./filter"
import { ITableItems } from "@/types/table"
// Internal Import
import { ITransaction } from "@/types/transaction"
import { MyTable } from ".."

interface IFinancialTableProps {
  items: ITransaction[]
  tableHeadItems?: ITableItems[]
  hasFilter?: boolean
  handleReset?: () => void
  handleChangeQueries?: (key: string, val: string) => void
  values?: any
  loading?: boolean
  queries?: any
}

export const customTableStyle: SxProps<Theme> = {
  border: "1px solid",
  borderRadius: "12px",
  overflow: "hidden",
  borderCollapse: "initial",
  borderColor: (t) => ` ${alpha(t.palette.common.black, 0.12)} !important`,
  [`& thead tr th`]: {
    borderLeft: "1px solid",
    borderBottom: "1px solid ",
    borderColor: (t) => alpha(t.palette.common.black, 0.12),
    [`&:first-of-type`]: {
      borderLeftColor: "transparent",
      textAlign: "center"
    }
  },
  [`& tbody tr`]: {
    [`& td`]: {
      fontSize: "14px",
      maxWidth: 130,
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontWeight: 500,
      borderLeft: "1px solid  !important",
      borderBottomWidth: "1px !important",
      borderColor: (t) => ` ${alpha(t.palette.common.black, 0.12)} !important`,
      color: (t) => t.palette.text.primary,
      p: 2,
      [`&:first-of-type`]: {
        borderLeftColor: "transparent  !important",
        textAlign: "center"
      }
    },
    [`&:last-child`]: {
      [`& td`]: {
        borderBottomColor: "transparent !important"
      }
    }
  }
}
const FinancialTable: React.FunctionComponent<IFinancialTableProps> = ({
  items = [],
  tableHeadItems = [],
  hasFilter = true,
  handleChangeQueries,
  handleReset,
  values = {},
  loading = false,
  queries = {}
}) => {
  return (
    <Stack
      spacing={0}
      sx={{
        [`& .DatePicker input`]: {
          borderRadius: "12px !important"
        }
      }}
    >
      {hasFilter ? (
        <FinancialTableFilter
          values={values}
          queries={queries}
          handleReset={handleReset}
          handleChangeQueries={handleChangeQueries}
        />
      ) : null}
      <MyTable
        sxProps={{
          ...customTableStyle
        }}
        items={items}
        tableHeadItems={tableHeadItems}
        loading={loading}
      />
    </Stack>
  )
}

export default FinancialTable
