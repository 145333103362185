//Built-in import
import * as React from "react";

//External import
import { Box } from "@mui/material";
import ChatboxInputButtonAttach from "./attach";
//Internal import
import ChatboxInputButtonText from "./text";
import { useUploader } from "@/hook";

interface IChatboxInputButtonProps {
  text: string;
  addMessage: Function;
  handleChangeFile: (file: File) => void;
}

const ChatboxInputButton: React.FunctionComponent<IChatboxInputButtonProps> = ({
  text,
  addMessage,
  handleChangeFile,
}) => {
  const { file, hiddenInput, hasFile, fileRemover } = useUploader();
  React.useEffect(() => {
    if (!file) return;
    handleChangeFile(file as File);
  }, [file]);

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        right: 8,
        transform: "translateY(-50%)",
      }}
    >
      <ChatboxInputButtonAttach
        fileRemover={fileRemover}
        hasFile={hasFile}
        hiddenInput={hiddenInput}
      />
      <ChatboxInputButtonText addMessage={addMessage} />
    </Box>
  );
};

export default React.memo(ChatboxInputButton);
