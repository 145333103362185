import "react-modern-calendar-datepicker/lib/DatePicker.css";

// Built-in import
import * as React from "react";
// External import
import * as Yup from "yup";

import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
// Internal import
import { MyDatePicker, TitleWithValue } from "@/components/shared";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import { LoadingButton } from "@mui/lab";
import { Report } from "@/services";
import { toaster } from "@/components/shared/toaster";
import { useFormik } from "formik";
import { useGlobalContext } from "@/context/global-context";
import { useTranslation } from "react-i18next";
import moment from "moment";

interface IEarlySettlementForm {
  projectId: string;
  onFetchAllEvents?: () => void;
}

export const EarlySettlementForm: React.FC<IEarlySettlementForm> = ({
  projectId,
  onFetchAllEvents,
}) => {
  const [type, setType] = React.useState(0);

  //get translation object
  const { t } = useTranslation();
  // date state
  const [date, setDate] = React.useState(null);
  // modal
  const { closeModal } = useGlobalContext();
  // formik
  const {
    isSubmitting,
    touched,
    errors,
    getFieldProps,
    handleSubmit,
    setSubmitting,
  } = useFormik({
    initialValues: {
      description: "",
      settlement_code: "",
    },
    validationSchema: Yup.object().shape({
      description: Yup.string().required(t("common:this_field_is_required")),
      settlement_code: Yup.string().required(
        t("common:this_field_is_required")
      ),
    }),
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true);
      try {
        await Report.Create(projectId, "Finance", values);
        onFetchAllEvents();
      } catch (e) {
      } finally {
        actions.setSubmitting(false);
        closeModal();
      }
    },
  });

  // Submit in future
  const submitInFuture = async () => {
    setSubmitting(true);
    try {
      await Report.Create(projectId, "Finance", {
        expires_at: moment(date).unix(),
      });
      toaster.success(t("common:withdraw_report_has_been_added"));
      closeModal();
      onFetchAllEvents();
    } catch (e) {
      toaster.error(e);
    } finally {
      setSubmitting(false);
      closeModal();
    }
  };

  return (
    <Stack
      minHeight={{ xs: "calc(100vh - 100px)", md: 400 }}
      px={2}
      justifyContent={"space-between"}
      alignItems="start"
      spacing={3}
      component="form"
      width={{ xs: "100%", md: "800px", lg: "1000px" }}
      onSubmit={handleSubmit}
      sx={{
        height: "100%",
        maxHeight: "700px",
        overflowY: "scroll",
      }}
    >
      <FormControl fullWidth>
        <RadioGroup
          defaultValue={type}
          onChange={(e) => setType(+e.target.value)}
        >
          <Grid container spacing={3} flexWrap="wrap-reverse">
            <Grid item xs={12} md={6}>
              <Stack spacing={2}>
                {/* radio button */}
                <FormControlLabel
                  value={0}
                  control={<Radio />}
                  label={t("common:register_new_report")}
                />
                <Stack spacing={3}>
                  {/* deposited interest percentage */}
                  <TitleWithValue
                    title={t("common:explanations_related_to_settlement")}
                    hasDoubleDot={false}
                    value={
                      <TextField
                        multiline
                        rows={7}
                        fullWidth
                        size="small"
                        placeholder={t("common:enter_relevant_description")}
                        {...getFieldProps("description")}
                        error={Boolean(
                          touched.description && errors.description
                        )}
                        helperText={touched.description && errors.description}
                      />
                    }
                    direction="column"
                    alignItems={"flex-start"}
                  />
                  {/* interest deposit code */}
                  <TitleWithValue
                    title={t("common:settlement_transaction_code")}
                    hasDoubleDot={false}
                    value={
                      <TextField
                        fullWidth
                        size="small"
                        placeholder={t("common:enter_transaction_code")}
                        {...getFieldProps("settlement_code")}
                        error={Boolean(
                          touched.settlement_code && errors.settlement_code
                        )}
                        helperText={
                          touched.settlement_code && errors.settlement_code
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <InfoOutlinedIcon color="info" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    }
                    direction="column"
                    alignItems={"flex-start"}
                  />
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack
                sx={{
                  borderLeft: ["none", , "1px solid"],
                  borderColor: (t) => ["none", , t.palette.grey[300]],
                  height: "100%",
                  pl: [0, , 3],
                }}
                spacing={2}
              >
                {/* radio button */}
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label={t("common:anticipate_future_reports")}
                />
                {/* date picker */}
                <TitleWithValue
                  title={t("common:time_of_publication_of_report")}
                  hasDoubleDot={false}
                  value={
                    <Stack width="100%">
                      <MyDatePicker
                        value={date}
                        onChangeDate={(date) => {
                          setDate(date);
                        }}
                        title={t("common:date_you_expect_to_report")}
                      />
                    </Stack>
                  }
                  direction="column"
                  alignItems={"flex-start"}
                />
              </Stack>
            </Grid>
          </Grid>
        </RadioGroup>
      </FormControl>

      {/* submit */}
      <Stack width="100%" alignItems={"flex-end"}>
        <LoadingButton
          loading={isSubmitting}
          variant="contained"
          type={type === 0 ? "submit" : "button"}
          {...(type === 1
            ? {
                onClick: submitInFuture,
              }
            : {})}
        >
          {t("common:record_report")}
        </LoadingButton>
      </Stack>
    </Stack>
  );
};
