import * as React from "react";
import { Stack, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import EventCard from "@/components/shared/event-card";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

interface ISubmitedEventProps {
  count: number;
  item: any;
}

const SubmitedEvent: React.FunctionComponent<ISubmitedEventProps> = ({
  item,
  count,
}) => {
  return (
    <Stack
      direction="row"
      alignItems={"flex-start"}
      spacing={1}
      width={["100%", , "calc(100% - 100px)"]}
    >
      <Typography variant="bodyMedium">{count}</Typography>
      <EventCard item={item} />
      <IconButton>
        <DeleteOutlinedIcon color="error" />
      </IconButton>
    </Stack>
  );
};

export default SubmitedEvent;
