import { IIconType } from "@/types/icon";
import * as React from "react";

interface IExpandIconProps extends IIconType {
  color?: string;
  width?: number;
  height?: number;
}

const ExpandIcon: React.FunctionComponent<IExpandIconProps> = ({
  color = "#fff",
  width = 30,
  height = 30,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M35.6673 3.49967C36.0007 3.66634 36.334 3.99967 36.5007 4.33301C36.6673 4.49967 36.6673 4.83301 36.6673 4.99967V14.9997C36.6673 15.9997 36.0007 16.6663 35.0007 16.6663C34.0007 16.6663 33.334 15.9997 33.334 14.9997V8.99967L17.834 24.4997C17.5007 24.833 17.1673 24.9997 16.6673 24.9997C16.1673 24.9997 15.834 24.833 15.5007 24.4997C14.834 23.833 14.834 22.833 15.5007 22.1663L31.0007 6.66634H25.0007C24.0007 6.66634 23.334 5.99967 23.334 4.99967C23.334 3.99967 24.0007 3.33301 25.0007 3.33301H35.0007C35.1673 3.33301 35.5007 3.33301 35.6673 3.49967ZM31.6673 31.6663V21.6663C31.6673 20.6663 31.0007 19.9997 30.0007 19.9997C29.0007 19.9997 28.334 20.6663 28.334 21.6663V31.6663C28.334 32.6663 27.6673 33.333 26.6673 33.333H8.33398C7.33398 33.333 6.66732 32.6663 6.66732 31.6663V13.333C6.66732 12.333 7.33398 11.6663 8.33398 11.6663H18.334C19.334 11.6663 20.0007 10.9997 20.0007 9.99967C20.0007 8.99967 19.334 8.33301 18.334 8.33301H8.33398C5.50065 8.33301 3.33398 10.4997 3.33398 13.333V31.6663C3.33398 34.4997 5.50065 36.6663 8.33398 36.6663H26.6673C29.5007 36.6663 31.6673 34.4997 31.6673 31.6663Z"
        fill="black"
      />
      <mask
        id="mask0_246_12696"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="34"
        height="34"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M35.6673 3.49967C36.0007 3.66634 36.334 3.99967 36.5007 4.33301C36.6673 4.49967 36.6673 4.83301 36.6673 4.99967V14.9997C36.6673 15.9997 36.0007 16.6663 35.0007 16.6663C34.0007 16.6663 33.334 15.9997 33.334 14.9997V8.99967L17.834 24.4997C17.5007 24.833 17.1673 24.9997 16.6673 24.9997C16.1673 24.9997 15.834 24.833 15.5007 24.4997C14.834 23.833 14.834 22.833 15.5007 22.1663L31.0007 6.66634H25.0007C24.0007 6.66634 23.334 5.99967 23.334 4.99967C23.334 3.99967 24.0007 3.33301 25.0007 3.33301H35.0007C35.1673 3.33301 35.5007 3.33301 35.6673 3.49967ZM31.6673 31.6663V21.6663C31.6673 20.6663 31.0007 19.9997 30.0007 19.9997C29.0007 19.9997 28.334 20.6663 28.334 21.6663V31.6663C28.334 32.6663 27.6673 33.333 26.6673 33.333H8.33398C7.33398 33.333 6.66732 32.6663 6.66732 31.6663V13.333C6.66732 12.333 7.33398 11.6663 8.33398 11.6663H18.334C19.334 11.6663 20.0007 10.9997 20.0007 9.99967C20.0007 8.99967 19.334 8.33301 18.334 8.33301H8.33398C5.50065 8.33301 3.33398 10.4997 3.33398 13.333V31.6663C3.33398 34.4997 5.50065 36.6663 8.33398 36.6663H26.6673C29.5007 36.6663 31.6673 34.4997 31.6673 31.6663Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_246_12696)">
        <rect width="40" height="40" fill={color} />
      </g>
    </svg>
  );
};

export default ExpandIcon;
