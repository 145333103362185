// Built-in import
import * as React from "react";
// External import
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
// Internal import
import { TitleWithDescription, Tips } from "@/components/shared";
import { dataTipsAdd } from "./data";

interface ISidebarAddExecutiveCalender {}

const SidebarAddExecutiveCalender: React.FC<
  ISidebarAddExecutiveCalender
> = () => {
  // Getting translation object
  const { t } = useTranslation(["common"]);

  return (
    <Stack spacing={3} mt={2}>
      <TitleWithDescription
        color="text.primary"
        headerVariant="subtitleBold"
        title={`${t("common:create_your_plan_execution_calendar")}.`}
        description={t("common:lorem") + t("common:lorem")}
        alignment={"justify"}
      />
      <Stack spacing={2}>
        <Stack direction="column" alignItems="start" spacing={1}>
          {dataTipsAdd.map((data) => (
            <Tips
              title={data.title}
              key={data.id}
              color={data.color}
              size="medium"
            />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SidebarAddExecutiveCalender;
