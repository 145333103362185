import { IIconType } from "@/types/icon";
import * as React from "react";

interface IUsersIconProps extends IIconType {}

const UsersIcon: React.FunctionComponent<IUsersIconProps> = ({
  color = "#5E5873",
  width = 23,
  height = 23,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.01594 7C4.01594 4.2 6.22471 2 9.03588 2C11.847 2 14.0558 4.2 14.0558 7C14.0558 9.8 11.847 12 9.03588 12C6.22471 12 4.01594 9.8 4.01594 7ZM18.0718 19V21C18.0718 21.6 17.6702 22 17.0678 22C16.4654 22 16.0638 21.6 16.0638 21V19C16.0638 17.3 14.7586 16 13.0518 16H5.01993C3.31315 16 2.00797 17.3 2.00797 19V21C2.00797 21.6 1.60638 22 1.00399 22C0.401594 22 0 21.6 0 21V19C0 16.2 2.20877 14 5.01993 14H13.0518C15.863 14 18.0718 16.2 18.0718 19ZM9.03588 10C7.3291 10 6.02392 8.7 6.02392 7C6.02392 5.3 7.3291 4 9.03588 4C10.7427 4 12.0478 5.3 12.0478 7C12.0478 8.7 10.7427 10 9.03588 10ZM20.2805 14.2C19.7785 14.1 19.1761 14.4 19.0757 14.9C18.9753 15.4 19.2765 16 19.7785 16.1C21.0837 16.4 21.9873 17.6 21.9873 19V21C21.9873 21.6 22.3889 22 22.9913 22C23.5937 22 23.9953 21.6 23.9953 21V19C24.0957 16.7 22.5897 14.7 20.2805 14.2ZM15.0598 2.9C15.2606 2.3 15.7626 2 16.2646 2.2C18.9753 2.8 20.5817 5.6 19.8789 8.4C19.4773 10.2 18.0718 11.5 16.2646 12H16.0638C15.5618 12 15.1602 11.7 15.0598 11.2C14.859 10.7 15.2606 10.1 15.7626 10C16.867 9.7 17.6702 8.9 17.9714 7.8C18.3729 6.1 17.369 4.5 15.7626 4.1C15.2606 4 14.9594 3.4 15.0598 2.9Z"
        fill="black"
      />
      <mask
        id="mask0_708_33979"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="2"
        width="24"
        height="20"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.01594 7C4.01594 4.2 6.22471 2 9.03588 2C11.847 2 14.0558 4.2 14.0558 7C14.0558 9.8 11.847 12 9.03588 12C6.22471 12 4.01594 9.8 4.01594 7ZM18.0718 19V21C18.0718 21.6 17.6702 22 17.0678 22C16.4654 22 16.0638 21.6 16.0638 21V19C16.0638 17.3 14.7586 16 13.0518 16H5.01993C3.31315 16 2.00797 17.3 2.00797 19V21C2.00797 21.6 1.60638 22 1.00399 22C0.401594 22 0 21.6 0 21V19C0 16.2 2.20877 14 5.01993 14H13.0518C15.863 14 18.0718 16.2 18.0718 19ZM9.03588 10C7.3291 10 6.02392 8.7 6.02392 7C6.02392 5.3 7.3291 4 9.03588 4C10.7427 4 12.0478 5.3 12.0478 7C12.0478 8.7 10.7427 10 9.03588 10ZM20.2805 14.2C19.7785 14.1 19.1761 14.4 19.0757 14.9C18.9753 15.4 19.2765 16 19.7785 16.1C21.0837 16.4 21.9873 17.6 21.9873 19V21C21.9873 21.6 22.3889 22 22.9913 22C23.5937 22 23.9953 21.6 23.9953 21V19C24.0957 16.7 22.5897 14.7 20.2805 14.2ZM15.0598 2.9C15.2606 2.3 15.7626 2 16.2646 2.2C18.9753 2.8 20.5817 5.6 19.8789 8.4C19.4773 10.2 18.0718 11.5 16.2646 12H16.0638C15.5618 12 15.1602 11.7 15.0598 11.2C14.859 10.7 15.2606 10.1 15.7626 10C16.867 9.7 17.6702 8.9 17.9714 7.8C18.3729 6.1 17.369 4.5 15.7626 4.1C15.2606 4 14.9594 3.4 15.0598 2.9Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_708_33979)">
        <rect width={width - 1} height={height - 1} fill={color} />
      </g>
    </svg>
  );
};

export default UsersIcon;
