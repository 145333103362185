import * as React from "react";

import { Stack, CircularProgress } from "@mui/material";

interface ILoadingEmbracedProps {}

const LoadingEmbraced: React.FunctionComponent<ILoadingEmbracedProps> = (
  props
) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ width: "100%", height: "100%", minHeight: 300 }}
    >
      <CircularProgress size={24} color="inherit" />
    </Stack>
  );
};

export default LoadingEmbraced;
