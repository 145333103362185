//Built-in import
import * as React from "react";
//External import
import { alpha, Breadcrumbs, Link, Typography } from "@mui/material";
//Internal import
import { IBreadcrumb } from "@/types/breadcrumb";

interface IPageBreadcrumbProps {
  items?: IBreadcrumb[];
}

const PageBreadcrumb: React.FunctionComponent<IPageBreadcrumbProps> = ({
  items = [],
}) => {
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      sx={{
        [`& .MuiBreadcrumbs-li`]: {
          [`& a`]: {
            textDecoration: "none",
          },
          [`& span`]: {
            color: (t) => alpha(t.palette.common.black, 0.7),
          },
        },
      }}
    >
      {/* Home Link */}
      {items.map((breadcrumb) => (
        <Link key={breadcrumb.id} href={breadcrumb.href}>
          <Typography variant={breadcrumb.id == 0 ? "bodyBold" : "bodyMedium"}>
            {breadcrumb.title}
          </Typography>
        </Link>
      ))}
    </Breadcrumbs>
  );
};

export default PageBreadcrumb;
