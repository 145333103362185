import * as React from "react";

import { EarlySettlementForm } from "./form";
import ModalContentWrapper from "@/components/shared/modal/modal-content-wrapper";
import { useTranslation } from "react-i18next";

interface IEarlySettlement {
  projectId: string;
  onFetchAllEvents?: () => void;
}

export const EarlySettlement: React.FC<IEarlySettlement> = ({
  projectId,
  onFetchAllEvents,
}) => {
  //Getting transaltion object
  const { t } = useTranslation();

  return (
    <ModalContentWrapper
      title={t("create_an_early_settlement_event")}
      titleVariant="subtitleBold"
    >
      <EarlySettlementForm
        projectId={projectId}
        onFetchAllEvents={onFetchAllEvents}
      />
    </ModalContentWrapper>
  );
};
