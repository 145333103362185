import * as React from "react";

import { Avatar, Stack, Theme, Typography } from "@mui/material";

import { AllVariants } from "@/mui/theme/declarations";
import { IMedia } from "@/types/media";
import { IUser } from "@/types/user";
import { SxProps } from "@mui/system";
import { useMedia } from "@/hook";
import { useTranslation } from "react-i18next";

interface IUserInformationProps {
  user: Partial<IUser> | null;
  direction?: "column" | "column-reverse" | "row" | "row-reverse";
  title?: string;
  sxProps?: SxProps<Theme>;
  variantTitle?: AllVariants;
  variantValue?: AllVariants;
}

const UserInformation: React.FunctionComponent<IUserInformationProps> = ({
  user,
  direction = "column",
  title = "",
  sxProps,
  variantTitle = "smallBold",
  variantValue = "extraSmallRegular",
}) => {
  const { t } = useTranslation(["common"]);

  const avatar = useMedia(user?.media, "avatar") as IMedia;

  if (!user) return null;

  return (
    <Stack direction="row" spacing={1} alignItems="center" sx={sxProps}>
      <Avatar
        src={avatar?.original_url || ""}
        sx={{
          width: 40,
          height: 40,
          border: "1px solid",
          borderColor: (t) => t.palette.common.white,
          boxShadow: "0px 0px 10px #66666670",
        }}
      />

      <Stack direction={direction}>
        <Typography variant={variantTitle} color="text.secondary">
          {user?.name || "-"}
        </Typography>
        {title && (
          <Typography variant={variantValue} color={"text.disabled"}>
            {title}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default UserInformation;
