import * as React from "react";
import { ProfitDepositTypes } from "..";
import { Stack, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import SubmitedEvent from "./event-items";
import AddIcon from "@mui/icons-material/Add";
import { PerformanceReportActionButtons } from "./../actions-button";

interface ISaveReportEventsProps {
  changeStepHandler: (step: ProfitDepositTypes) => void;
}

const eventItems = [
  {
    id: 1,
    meta: "1401/05/04",
    status: "presentation_report_plan",
  },
];

const SaveReportEvents: React.FunctionComponent<ISaveReportEventsProps> = ({
  changeStepHandler,
}) => {
  //Getting translation object
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Stack spacing={2}>
        <Typography component={"h3"} variant={"bodyMedium"}>
          {t(
            "enter_times_you_expect_to_report_to_investors_during_project_in_section"
          )}
        </Typography>
        <Stack spacing={1}>
          {eventItems.map((event, index) => {
            return (
              <SubmitedEvent key={event.id} item={event} count={index + 1} />
            );
          })}
        </Stack>
        <Stack direction={"row"}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() =>
              changeStepHandler(ProfitDepositTypes.ForcastDate)
            }
          >
            {t("add_new_report")}
          </Button>
        </Stack>
      </Stack>
      <PerformanceReportActionButtons
        handlePrevStep={() =>
          changeStepHandler(ProfitDepositTypes.ForcastDate)
        }
        handleNextStep={() => alert("event submitted")}
        submtBtnTitle={t("save_events")}
      />
    </React.Fragment>
  );
};

export default SaveReportEvents;
