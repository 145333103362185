import * as React from "react"

import { Stack, Typography } from "@mui/material"

import { Link } from "react-router-dom"
import routes from "@/navigation/routes"
import { useQuery } from "@/hook"
import { useTranslation } from "react-i18next"

interface IEditMobileNumberProps {}

export const EditMobileNumber: React.FunctionComponent<
  IEditMobileNumberProps
> = (props) => {
  const { t } = useTranslation()
  const query = useQuery()
  const resetWay = query.get("reset_way")

  const EditNumberLink = routes.login

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      spacing={0.5}
      sx={{ "& a": { color: (t) => t.palette.primary.main, fontSize: "12px" } }}
    >
      <Link to={EditNumberLink}>{t("edit")}</Link>
      <Typography variant="extraSmallRegular">{t("mobile_number")}</Typography>
    </Stack>
  )
}
