//Built-in import
import * as React from "react";

import { Drawer, SxProps, ThemeProvider } from "@mui/material";
import { createContext, useContext } from "react";

//Internal import
import { SharedModal } from "@/components/shared";
import { Theme } from "@mui/material";
import { createMyTheme } from "@/mui";
import { paletteModeSelector } from "@/store/slices/settings/selectors";
import { useAppSelector } from "@/store/store";

interface IGlobalContext {
  showModal: (element: React.ReactNode, sxProps?: SxProps<Theme>) => void;
  closeModal: () => void;
  showDrawer: (element: React.ReactNode, sxProps?: SxProps<Theme>) => void;
  closeDrawer: () => void;
}

const initialState: IGlobalContext = {
  showModal: (element: React.ReactNode, sxProps?: SxProps<Theme>) => {},
  closeModal: () => {},
  showDrawer: (element: React.ReactNode, sxProps?: SxProps<Theme>) => {},
  closeDrawer: () => {},
};

const GlobalContext = createContext(initialState);

interface IGlobalContextProviderProps {
  children?: React.ReactNode;
}

interface IModalContext {
  id: number;
  element: React.ReactNode;
  isShowModal: boolean;
  styles?: SxProps<Theme>;
}

const GlobalContextProvider: React.FC<IGlobalContextProviderProps> = ({
  children,
}) => {
  //Variable for opening state of modal
  const [isShowModal, setShowModal] = React.useState(false);
  //Content of modal
  const [modalContext, setModalContent] = React.useState<IModalContext[]>([]);
  //Variable for storing sx attrutes (For styling purposes)
  const [sxModal, setSxModal] = React.useState<SxProps<Theme>>({});

  //function for opening modal
  const showModal = (element: React.ReactNode, sxProps?: SxProps<Theme>) => {
    //For setting sx modal attributes
    if (sxProps) setSxModal(sxProps);

    setModalContent((crt) => {
      return [
        ...crt,
        {
          id: Math.floor(Math.random() * 10000),
          element,
          isShowModal: true,
          ...(sxProps && { styles: sxProps }),
        },
      ];
    });

    //Set modal content
    setShowModal(true);
  };
  //function for closing modal
  const closeModal = () => {
    //For removing sx modal attributes
    // if (Boolean(Object.keys(sxModal).length)) setSxModal({});
    //Set modal state variable
    // setShowModal(false);
    //Remove modal content
    // setModalContent(null);
    setModalContent((crt) => [...crt].slice(0, -1));
  };
  //Variable for opening state of modal
  const [isShowDrawer, setShowDrawer] = React.useState(false);
  //Content of modal
  const [drawerContext, setDrawerContext] =
    React.useState<React.ReactNode>(null);
  //Variable for storing sx attrutes (For styling purposes)
  const [sxDrawer, setSxDrawer] = React.useState<SxProps<Theme>>({});

  const showDrawer = (element: React.ReactNode, sxProps?: SxProps<Theme>) => {
    //For setting sx modal attributes
    if (sxProps) setSxDrawer(sxProps);
    //Set drawer content
    setDrawerContext(element);
    setShowDrawer(true);
  };

  const closeDrawer = () => {
    // if (Boolean(Object.keys(sxDrawer).length)) setSxDrawer({});
    setShowDrawer(false);
    setTimeout(() => setDrawerContext(null), 300);
  };

  //Getting palette mode
  const paletteMode = useAppSelector(paletteModeSelector);
  return (
    <GlobalContext.Provider
      value={{
        showModal,
        closeModal,
        showDrawer,
        closeDrawer,
      }}
    >
      <ThemeProvider theme={createMyTheme(paletteMode === "dark")}>
        {/* ModalComponent */}

        {modalContext.map((ctx, index) => {
          return (
            <SharedModal
              key={index}
              open={ctx?.isShowModal || false}
              handleClose={closeModal}
              sx={ctx?.styles}
            >
              {ctx.element}
            </SharedModal>
          );
        })}

        {/* ModalComponent */}
        {/* DrawerComponent */}
        <Drawer open={isShowDrawer} onClose={closeDrawer} sx={sxDrawer}>
          {drawerContext}
        </Drawer>
        {/* DrawerComponent */}
        {/* Children ReactNode */}
        {children}
        {/* Children ReactNode */}
      </ThemeProvider>
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
export default GlobalContextProvider;
