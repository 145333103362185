// Built-in import
import * as React from "react";

import { Box, Stack, SxProps, Theme } from "@mui/material";
//External import
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";

import AddEventButton from "./button";
import EditEventModal from "./modal/edit-event-modal";
// Internal import
import { EventCard } from "@/components/shared";
import { IEvent } from "@/types/event";
import { IEventType } from "@/types/event";
import { IProperty } from "@/types/properties";
import { IServerProperty } from "@/store/slices/property";
import NoItemsWasFound from "@/components/shared/no-items";
import { useAppSelector } from "@/store/store";
import { useGlobalContext } from "@/context/global-context";
import { userSelector } from "@/store/slices/auth/selectors";

interface IExecutiveCalendarCards {
  property?: IProperty & IServerProperty;
  events?: IEvent[];
  handleSelectEventId?: (event: IEvent, isEditable: boolean) => void;
  onFetchAllEvents?: () => void;
  isEdit?: boolean;
}

const ExecutiveCalendarCards: React.FC<IExecutiveCalendarCards> = ({
  property,
  isEdit = false,
  events = [],
  onFetchAllEvents,
  handleSelectEventId,
}) => {
  //getting modal from global context

  const { showModal } = useGlobalContext();

  // Style timeline
  const styleTimeLine = {
    alignItems: "start",
    justifyContent: "start",
    textAlign: "left",
    "& .MuiTimelineItem-root": {
      width: "100%",
      "& .MuiTimelineConnector-root": {
        display: events.length ? "block" : "none",
      },
      "&::before": {
        p: "0 !important",
        m: "0 !important",
        width: "1px !important",
        display: "none",
      },
    },
  };
  // Style main timeline
  const styleMainTimeLine: any = {
    borderRadius: 2,
    border: "1px solid #99999940",
    overflowY: "scroll",
    overflowX: ["scroll", "hidden"],
    height: "100%",
    minHeight: "600px",
    maxHeight: "600px",
    px: { xs: 0, md: 2 },
  };
  //style for edit event modal
  const editEventModalStyle: SxProps<Theme> = {
    width: "100vw",
    height: "100vh",
    maxHeight: "auto",
    borderTopRightRadius: "none",
    borderTopLeftRadius: "none",
    borderBottomRightRadius: "none",
    borderBottomLeftRadius: "none",
  };

  const handleOpenEditEventModal = (event: IEvent) => {
    showModal(
      <EditEventModal
        item={event}
        projectId={property.id}
        onFetch={onFetchAllEvents}
      />,
      editEventModalStyle
    );
  };

  // if (!events.length) return <NoItemsWasFound />;
  return (
    <Stack sx={styleMainTimeLine}>
      <Timeline
        sx={{
          ...styleTimeLine,
        }}
        onReset={undefined}
        onResetCapture={undefined}
      >
        {/* executive calender card */}
        {events.length ? (
          events.map((event, key) => {
            const isFirstCard = key === 0;

            return (
              <TimelineItem
                key={event.id}
                className={!isFirstCard ? "isDisabled" : ""}
              >
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  {!isEdit ? (
                    <Box
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleOpenEditEventModal(event)}
                    >
                      <EventCard item={event} />
                    </Box>
                  ) : (
                    <Box
                      {...(handleSelectEventId && {
                        onClick: () => handleSelectEventId(event, isFirstCard),
                      })}
                      sx={{ cursor: "pointer" }}
                    >
                      <EventCard item={event} />
                    </Box>
                  )}
                </TimelineContent>
              </TimelineItem>
            );
          })
        ) : (
          <NoItemsWasFound />
        )}
        {/* button add time line */}
        <AddEventButton
          propertyId={property?.id}
          onFetchAllEvents={onFetchAllEvents}
        />
      </Timeline>
    </Stack>
  );
};

export default ExecutiveCalendarCards;
