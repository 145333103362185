const routes = {
  index: "/",
  login: "/login",
  "forget-password": "/forget-password",
  register: "/register",
  dashboard: {
    index: "dashboard",
  },
  users: {
    index: "dashboard/users",
    funders: "funders",
    investors: "investors",
    managers: "managers",
    show: "show/:id",
  },
  notifications: {
    index: "dashboard/notifications",
  },
  addNewAdmin: "dashboard/add-new-admin",
  properties: {
    index: "dashboard/properties",
    "my-properties": "my-properties",
    add: "add-property",
    show: "show/:id",
  },
  requests: {
    index: "dashboard/requests",
  },
  tickets: {
    index: "dashboard/tickets",
    show: "show/:id",
  },
  roles: {
    index: "dashboard/roles",
    show: "show/:id",
    add: "add-role",
  },
  financial: {
    index: "dashboard/financial",
    paybacks: "payback",
  },
  reports: {
    index: "dashboard/reports",
  },
  blog: {
    index: "dashboard/blog",
    categories: "categories",
    addCategories: "categories/add",
    showCategory: "categories/show/:id",
    show: "show/:id",
    add: "add",
  },
  comments: {
    index: "dashboard/comments",
    show: "show/:id",
  },
}

export default routes
