import { TypedUseSelectorHook, useSelector } from "react-redux"
import { persistReducer, persistStore } from "redux-persist"

import { configureStore } from "@reduxjs/toolkit"
import { rootReducer } from "."
import storage from "redux-persist/lib/storage"
import thunk from "redux-thunk"

//Persist config
const persistConfig = {
  key: "token-platform-admin",
  storage,
  blacklist: ["property"]
}

//Persisted
const persistedReducer = persistReducer(persistConfig, rootReducer)

//Store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
  devTools: process.env.NODE_ENV !== "production"
})

const persistor = persistStore(store)

//Getting typeof store
export type StoreType = ReturnType<typeof rootReducer>

//UseSelector Localy
const useAppSelector: TypedUseSelectorHook<StoreType> = useSelector

export { store, persistor, useAppSelector }
