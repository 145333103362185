import * as React from "react"

import { Navigate, Route, Routes } from "react-router-dom"
import {
  actionAuthSetLoading,
  actionAuthSetLoadingOff,
  actionSetLogout,
  actionSetUser,
} from "@/store/slices/auth"

import LazyRoute from "./lazy"
import PrivateRoute from "./PrivateRoute"
import { User } from "@/services"
import routes from "./routes"
import { useAuth } from "@/hook"
import { useDispatch } from "react-redux"

//not found page
const NotFoundPage = React.lazy(
  () => import("@/components/shared/not-found-page")
)
//Panel page
const DashboardPage = React.lazy(() => import("@/components/pages/dashboard"))
//Panel page
const DashboardWrapper = React.lazy(
  () => import("@/containers/dashboard-wrapper")
)

// Properties page
const Properties = React.lazy(
  () => import("@/components/pages/dashboard/properties/content")
)
// Tickets page
const Tickets = React.lazy(() => import("@/components/pages/dashboard/tickets"))
// Add New Property page
const AddNewProperty = React.lazy(
  () => import("@/components/shared/add-property")
)
// Edit Property page
const ShowProperty = React.lazy(
  () => import("@/components/pages/dashboard/properties/edit-properties")
)
const SingleTicket = React.lazy(
  () => import("@/components/pages/dashboard/tickets/show")
)
//Notifications page
const Notifications = React.lazy(
  () => import("@/components/pages/dashboard/notifications")
)
//Roles page
const RolesManagement = React.lazy(
  () => import("@/components/pages/dashboard/roles-management")
)
const SingleRolePage = React.lazy(
  () => import("@/components/pages/dashboard/roles-management/show")
)
const AddRolePage = React.lazy(
  () => import("@/components/pages/dashboard/roles-management/add")
)
// Requests page
const Requests = React.lazy(
  () => import("@/components/pages/dashboard/requests")
)
// Requests page
const AddNewAdmin = React.lazy(
  () => import("@/components/pages/dashboard/add-new-admin")
)
// Financial page
const FinancialManagement = React.lazy(
  () => import("@/components/pages/dashboard/financial-management")
)
const FinancialPayback = React.lazy(
  () => import("@/components/pages/dashboard/financial-management/paybacks")
)
// User Details page
const UserDetailsPage = React.lazy(
  () => import("@/components/pages/dashboard/users/single")
)
// User Details page
const UsersPage = React.lazy(
  () => import("@/components/pages/dashboard/users/index")
)
// User Details page
const BlogPage = React.lazy(() => import("@/components/pages/dashboard/blog"))
// Reports page
const ReportPage = React.lazy(
  () => import("@/components/pages/dashboard/report/index")
)
//blog page details

const ManageBlogDetails = React.lazy(
  () => import("@/components/pages/dashboard/blog/partials/post-information")
)
//blogs categories
const Blogscategories = React.lazy(
  () => import("@/components/pages/dashboard/blog/categories")
)
//Add new blog category
const AddNewBlogCategory = React.lazy(
  () => import("@/components/pages/dashboard/blog/categories/add")
)
//Login Page
const LoginPage = React.lazy(() => import("@/components/pages/login"))
const ForgetPasswordPage = React.lazy(
  () => import("@/components/pages/dashboard/forget-password")
)

//Comments page

const CommentsPage = React.lazy(
  () => import("@/components/pages/dashboard/comments")
)

const RouteElements = () => {
  const { isLoggedIn } = useAuth()

  const dispatcher = useDispatch()

  const getUserProfile = async () => {
    //Showing loading
    dispatcher(actionAuthSetLoading)
    try {
      const res = await User.Get()
      const userProfile = res?.data?.data?.item || null
      dispatcher(actionSetUser(userProfile))
    } catch (e) {
      //Loggin out user
      dispatcher(actionSetLogout)
    } finally {
      //Disableing loading
      dispatcher(actionAuthSetLoadingOff)
    }
  }

  //Getting profile
  React.useEffect(() => {
    if (isLoggedIn) getUserProfile()
  }, [isLoggedIn])

  // deconstract route names from routes
  const {
    dashboard,
    index,
    login,
    users,
    properties,
    tickets,
    notifications,
    roles,
    blog,
    requests,
    addNewAdmin,
    financial,
    reports,
    comments,
  } = routes

  return (
    <Routes>
      <Route
        index
        element={<LazyRoute element={<Navigate to={`${dashboard.index}`} />} />}
      />
      <Route path={login} element={<LazyRoute element={<LoginPage />} />} />
      <Route
        path={routes["forget-password"]}
        element={<LazyRoute element={<ForgetPasswordPage />} />}
      />
      <Route
        path={index}
        element={
          <LazyRoute
            element={<PrivateRoute element={<DashboardWrapper />} />}
          />
        }
      >
        <Route path={addNewAdmin}>
          <Route index element={<LazyRoute element={<AddNewAdmin />} />} />
        </Route>
        <Route path={dashboard.index}>
          <Route index element={<LazyRoute element={<DashboardPage />} />} />
        </Route>
        <Route path={users.index}>
          <Route index element={<LazyRoute element={<UsersPage />} />} />
          <Route
            path={users.funders}
            element={<LazyRoute element={<UsersPage type="funders" />} />}
          />
          <Route
            path={users.investors}
            element={<LazyRoute element={<UsersPage type="investors" />} />}
          />
          <Route
            path={users.managers}
            element={<LazyRoute element={<UsersPage type="admins" />} />}
          />
          <Route
            path={users.show}
            element={<LazyRoute element={<UserDetailsPage />} />}
          />
        </Route>
        <Route path={properties.index}>
          <Route index element={<LazyRoute element={<Properties />} />} />
          <Route
            path={properties.add}
            element={<LazyRoute element={<AddNewProperty />} />}
          />
          <Route
            path={properties.show}
            element={<LazyRoute element={<ShowProperty />} />}
          />
        </Route>
        <Route path={tickets.index}>
          <Route index element={<LazyRoute element={<Tickets />} />} />
          <Route
            path={tickets.show}
            element={<LazyRoute element={<SingleTicket />} />}
          />
        </Route>
        <Route path={notifications.index}>
          <Route index element={<LazyRoute element={<Notifications />} />} />
        </Route>
        <Route path={roles.index}>
          <Route index element={<LazyRoute element={<RolesManagement />} />} />
          <Route
            path={roles.show}
            element={<LazyRoute element={<SingleRolePage />} />}
          />
          <Route
            path={roles.add}
            element={<LazyRoute element={<AddRolePage />} />}
          />
        </Route>
        <Route path={requests.index}>
          <Route index element={<LazyRoute element={<Requests />} />} />
        </Route>
        <Route path={financial.index}>
          <Route
            index
            element={<LazyRoute element={<FinancialManagement />} />}
          />
          <Route
            path={financial.paybacks}
            element={<LazyRoute element={<FinancialPayback />} />}
          />
        </Route>
        <Route path={blog.index}>
          <Route index element={<LazyRoute element={<BlogPage />} />} />
          <Route path={blog.categories}>
            <Route
              index
              element={<LazyRoute element={<Blogscategories />} />}
            />
          </Route>
          <Route
            path={blog.addCategories}
            element={<LazyRoute element={<AddNewBlogCategory />} />}
          />
          <Route
            path={blog.showCategory}
            element={<LazyRoute element={<AddNewBlogCategory />} />}
          />
          <Route
            path={blog.show}
            element={<LazyRoute element={<ManageBlogDetails />} />}
          />
          <Route
            path={blog.add}
            element={<LazyRoute element={<ManageBlogDetails />} />}
          />
        </Route>
        <Route path={reports.index}>
          <Route index element={<LazyRoute element={<ReportPage />} />} />
        </Route>
        <Route path={comments.index}>
          <Route index element={<LazyRoute element={<CommentsPage />} />} />
        </Route>
      </Route>
      <Route path="/404" element={<LazyRoute element={<NotFoundPage />} />} />
      <Route path="*" element={<Navigate to={"/404"} replace />} />
    </Routes>
  )
}

export default RouteElements
