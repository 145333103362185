// Built-in import
import * as React from "react";

import FutureCardContent from "./future";
import { IEvent } from "@/types/event";
// External import
import { Stack } from "@mui/material";
// Internal import
import { TitleWithValue, VerifyRejectionButton } from "@/components/shared";
import { useTranslation } from "react-i18next";
import { Report } from "@/services";
import { toaster } from "@/components/shared/toaster";

interface IChildrenDefaultEvent {
  item?: IEvent;
}

const ChildrenDefaultEvent: React.FC<IChildrenDefaultEvent> = ({ item }) => {
  // Getting translation object
  const { t } = useTranslation(["common"]);

  //checking for select deleted item from client and awaiting admin confirmation
  const isPendingDelete = Boolean(
    item.status === "Deleted" && item.deleted_at === null
  );

  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [deleteLoading, setDeleteLoading] = React.useState(false);

  const handleDelete = async () => {
    setDeleteLoading(true);
    try {
      const res = await Report.DeleteEvent(item.type, item.id);
      toaster.success(res.data.message);
    } catch (error) {
      toaster.error(error.response.data);
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleConfirmEvent = async () => {
    setConfirmLoading(true);
    try {
      const res = await Report.ConfirmEvent(item.id, item.type);
      toaster.success(res.data.message);
    } catch (error) {
      toaster.error(error.response.data);
    } finally {
      setConfirmLoading(false);
    }
  };
  const EventCardHolderWithConfirmation = (children: React.ReactNode) => {
    return (
      <React.Fragment>
        {children}
        {isPendingDelete ? (
          <VerifyRejectionButton
            verifyOnclick={handleConfirmEvent}
            rejectionOnclick={handleDelete}
            confirmloading={confirmLoading}
            rejectloading={deleteLoading}
            rejectionTitle={t("common:delete_event")}
            verifyTitle={t("common:confirm_event")}
          />
        ) : null}
      </React.Fragment>
    );
  };

  //add holder for wrap components in delet event approval

  if (item.expires_at)
    return EventCardHolderWithConfirmation(<FutureCardContent item={item} />);

  return EventCardHolderWithConfirmation(
    <Stack direction="column" pb={1} spacing={1}>
      <TitleWithValue
        title={item.title}
        value={""}
        hasDoubleDot={false}
        justifyContent="space-between"
        colorTitle={"text.secondary"}
        variantTitle="bodyBold"
        variantValue={"bodyMedium"}
        alignItems="flex-start"
      />
      {item.revenue_percent && (
        <TitleWithValue
          title={t("common:depsoited_profit")}
          value={`${item.revenue_percent}%`}
          hasDoubleDot={false}
          justifyContent="space-between"
          colorTitle={"text.secondary"}
          variantTitle="bodyBold"
          variantValue={"bodyMedium"}
          alignItems="flex-start"
        />
      )}
      <TitleWithValue
        title={item.description}
        value={""}
        hasDoubleDot={false}
        justifyContent="space-between"
        colorTitle={"text.secondary"}
        variantTitle="bodyBold"
        variantValue={"bodyMedium"}
        alignItems="flex-start"
      />
    </Stack>
  );
};

export default ChildrenDefaultEvent;
