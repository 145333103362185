import { IIconType } from "@/types/icon";
import * as React from "react";

interface IPlusIconProps extends IIconType {}

const PlusIcon: React.FunctionComponent<IPlusIconProps> = ({
  color = "#0EAD98",
  width = 33,
  height = 33,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.2826 16.0837C27.2826 16.8837 26.7492 17.417 25.9492 17.417H17.9492V25.417C17.9492 26.217 17.4159 26.7503 16.6159 26.7503C15.8159 26.7503 15.2826 26.217 15.2826 25.417V17.417H7.28255C6.48255 17.417 5.94922 16.8837 5.94922 16.0837C5.94922 15.2837 6.48255 14.7503 7.28255 14.7503H15.2826V6.75033C15.2826 5.95033 15.8159 5.41699 16.6159 5.41699C17.4159 5.41699 17.9492 5.95033 17.9492 6.75033V14.7503H25.9492C26.7492 14.7503 27.2826 15.2837 27.2826 16.0837Z"
        fill="black"
      />
      <mask
        id="mask0_185_13929"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="5"
        y="5"
        width="23"
        height="22"
      >
        <path
          d="M27.2826 16.0837C27.2826 16.8837 26.7492 17.417 25.9492 17.417H17.9492V25.417C17.9492 26.217 17.4159 26.7503 16.6159 26.7503C15.8159 26.7503 15.2826 26.217 15.2826 25.417V17.417H7.28255C6.48255 17.417 5.94922 16.8837 5.94922 16.0837C5.94922 15.2837 6.48255 14.7503 7.28255 14.7503H15.2826V6.75033C15.2826 5.95033 15.8159 5.41699 16.6159 5.41699C17.4159 5.41699 17.9492 5.95033 17.9492 6.75033V14.7503H25.9492C26.7492 14.7503 27.2826 15.2837 27.2826 16.0837Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_185_13929)">
        <rect
          x="0.616211"
          y="0.0839844"
          width={width}
          height={height}
          fill="white"
        />
      </g>
    </svg>
  );
};

export default PlusIcon;
