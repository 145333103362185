import * as React from "react";
import TitleWithValue from "@/components/shared/title-with-value";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { PerformanceReportActionButtons } from "../actions-button";
import { ProfitDepositTypes } from "..";
import { useGlobalContext } from "@/context/global-context";
import AddEventModalContent from "../../..";
import AddIcon from "@mui/icons-material/Add";

interface IAddDepositDateProps {
  changeStepHandler: (step: ProfitDepositTypes) => void;
}

const AddDepositDate: React.FunctionComponent<IAddDepositDateProps> = ({
  changeStepHandler,
}) => {
  //get translation object
  const { t } = useTranslation();
  //get showModal and close modal from context
  const { showModal, closeModal } = useGlobalContext();
  return (
    <React.Fragment>
      <TitleWithValue
        direction={"column"}
        hasDoubleDot={false}
        spacing={2}
        alignItems={"flex-start"}
        title={t(
          "anticipate_timing_of_deposit_of_plan_interest_for_your_investors"
        )}
        value={
          <Button
            variant="contained"
            onClick={() => changeStepHandler(ProfitDepositTypes.ForcastDate)}
            startIcon={<AddIcon />}
          >
            {t("add_interest_payment_date")}
          </Button>
        }
      />
      <PerformanceReportActionButtons
        handlePrevStep={() => {
          closeModal();
          showModal(<AddEventModalContent />, { width: "830px" });
        }}
      />
    </React.Fragment>
  );
};

export default AddDepositDate;
