import { IMedia } from "../media";
import { IUser } from "../user";

export interface ITitleItems {
  title: string;
  tag?: string;
}
export interface IProgressInvestmentItems {
  capital: string | number;
  progressValue: string | number;
}
export interface ISummaryItem {
  successful_projects: string | number;
  volume_of_investment: string | number;
  investor: string | number;
}
export enum AddPropertyStepTypes {
  BasicPlanInfo = 0,
  CapitalizationDetails = 1,
  AdditionalInfo = 2,
  ExecutiveCalender = 3,
}

export interface IProperty {
  admin_id: string | null;
  media?: any;
  advantage_statement: string;
  available_shares: string;
  company_statement: string;
  created_at: string;
  deleted_at: string | null;
  excerpt: string | null;
  description: string | null;
  expires_at: string;
  finance_statement: string;
  id: string;
  investment_ratio: string;
  period_months: number | null;
  average_profit: string | null;
  is_guaranteed: boolean;
  market_statement: string;
  max_investment_quantity: number;
  total_investment_quantity?: string;
  total_investment_percent?: number;
  investment_period_months: number;
  meta: any;
  min_investment_quantity: number;
  monthly_wage: number | string;
  background: string | File | IMedia;
  owner_id: number;
  owner_name: string;
  owner?: IUser;
  required_investment_quantity: string;
  risk_statement: string;
  share_price: string;
  sold_shares: any;
  starts_at: any;
  status: string;
  title: string;
  total_shares: any;
  updated_at: string;
  collateral_type?: null | string;
}
