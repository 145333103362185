//  Built-in import
import * as React from "react";

// External Import
import { Button, Stack, TextField } from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";

const SearchBoxWithButton: React.FunctionComponent<any> = (props) => {
  // Getting Translation
  const { t } = useTranslation(["common"]);
  return (
    <Stack
      direction="row"
      sx={{
        width: "100%",
        [`& .MuiButtonBase-root`]: {
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          width: "auto",
          minWidth: "auto",
          px: 1,
        },

        [`& .MuiFormControl-root`]: {
          flexGrow: 1,
          [`& .MuiInputBase-root`]: {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            height: "100%",
            "&.Mui-focused fieldset": {
              borderWidth: ".5px",
              borderRightWidth: "0px",
            },
            [`& .MuiInputBase-input`]: {
              py: 1,
              bgcolor: (t) => t.palette.common.white,
              fontSize: "12px",
            },
            "& fieldset": {
              borderRightWidth: 0,
            },
          },
        },
      }}
    >
      <TextField {...props} />
      <Button variant="outlined">
        <SearchIcon />
      </Button>
    </Stack>
  );
};

export default SearchBoxWithButton;
