// Built-in import
import * as React from "react";

// External import
import { Stack, useTheme } from "@mui/material";

import AddEventCard from "./card";
// Internal import
import DollarIcon from "@/components/icons/dollar-icon";
import { ExpandIcon } from "@/components/icons";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import { useTranslation } from "react-i18next";

export enum AddEventTypes {
  EventCardsListing = 0,
  PerformanceReport = 1,
  ProfitDeposit = 2,
  EarlySettlement = 3,
}
interface IAddEventCardsListing {
  propertyId?: string;
  onFetchAllEvents?: () => void;
}

export const AddEventCardsListing: React.FC<IAddEventCardsListing> = ({
  propertyId,
  onFetchAllEvents,
}) => {
  // Getting translation object
  const { t } = useTranslation(["common"]);
  //Getting mui theme
  const theme = useTheme();

  const addCardData = [
    {
      id: 1,
      icon: (
        <DollarIcon color={theme.palette.primary.main} width={34} height={34} />
      ),
      type: AddEventTypes.PerformanceReport,
      title: t("common:performance_report"),
      description: t("common:performance_report_description"),
    },
    {
      id: 2,
      icon: <TextSnippetOutlinedIcon fontSize="large" />,
      type: AddEventTypes.ProfitDeposit,
      title: t("common:interest_deposit"),
      description: t("common:deposit_profit_description"),
    },
  ];
  if (propertyId) {
    addCardData.push({
      id: 3,
      icon: <ExpandIcon color={theme.palette.primary.main} />,
      type: AddEventTypes.EarlySettlement,
      title: t("common:early_settlement"),
      description: t("common:early_settlement_description"),
    });
  }

  return (
    <Stack
      sx={{ px: [1, , 4] }}
      spacing={5}
      alignItems="center"
      justifyContent="center"
    >
      <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
        {addCardData.map((data) => (
          <AddEventCard
            onFetchAllEvents={onFetchAllEvents}
            key={data.id}
            item={data}
            {...(propertyId && { propertyId })}
          />
        ))}
      </Stack>
    </Stack>
  );
};
