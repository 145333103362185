import * as React from "react"

import { calcPageCount, getUrlWithQueryParams } from "@/utils"

import { IResponseForFetchPaginationEntity } from "@/api/types"
import { ITableItems } from "@/types/table"
import { MyTable } from ".."
import { Stack } from "@mui/material"
import { WithPaginationProps } from "@/hoc/withPagination/interface"
import { customTableStyle } from "../financial-table"
import { t } from "i18next"
import useFetch from "@/hook/use-fetch"
import { withPagination } from "@/hoc/withPagination"

interface IDynamicListingProps extends WithPaginationProps {
  endpoint: string
  tableItems?: ITableItems[]
  queries?: object
  perPage?: number
  title?: string
  hasPagination?: boolean
}

const DynamicListing: React.FunctionComponent<IDynamicListingProps> = ({
  endpoint,
  tableItems = [],
  page,
  title,
  hasPagination = true,
  renderPagination,
  queries = {},
  perPage = 16,
}) => {
  const { data: fetchedData, loading: fetchLoading } = useFetch<
    IResponseForFetchPaginationEntity<any>
  >(
    getUrlWithQueryParams(endpoint, {
      ...queries,
      per_page: perPage,
      page,
    })
  )

  const fetchedDataItems =
    (fetchedData && fetchedData.data.items.data) || [] || []

  const totalItems = (fetchedData && fetchedData.data.items.total) || 0

  const pageCount = calcPageCount(totalItems)

  return (
    <Stack direction="column" spacing={2}>
      <MyTable
        {...(title && { title })}
        items={fetchedDataItems}
        loading={fetchLoading}
        tableHeadItems={tableItems}
        sxProps={customTableStyle}
      />
      {hasPagination &&
        Boolean(pageCount && pageCount > 1) &&
        renderPagination(pageCount)}
    </Stack>
  )
}

export default withPagination(DynamicListing)
