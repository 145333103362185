import * as React from "react";

import { IconButton, Stack, Typography, SxProps, Theme } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { useGlobalContext } from "@/context/global-context";
import { AllVariants } from "@/mui/theme/declarations";

interface IModalContentWrapperProps {
  title?: string;
  titleVariant?: AllVariants;
  children: React.ReactNode;
  sxProps?: SxProps<Theme>;
  onFetch?: () => void;
}

const ModalContentWrapper: React.FunctionComponent<
  IModalContentWrapperProps
> = ({ title, titleVariant = "bodyBold", children, onFetch, sxProps = {} }) => {
  // Getting global context
  const { closeModal } = useGlobalContext();

  return (
    <Stack spacing={4} className="modal-content-wrapper" sx={sxProps}>
      {Boolean(title) && (
        <Stack
          direction="row"
          justifyContent="center"
          sx={{ position: "relative" }}
        >
          <Typography variant={titleVariant} color="text.primary">
            {title}
          </Typography>
          {/* Closing modal */}
          <IconButton
            onClick={() => {
              closeModal();
              if (onFetch) onFetch();
            }}
            sx={{
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              right: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
          {/* Closing modal */}
        </Stack>
      )}
      {children}
    </Stack>
  );
};

export default ModalContentWrapper;
