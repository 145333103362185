import * as React from "react"

interface IRepeatIconProps {
  color?: string
}

const RepeatIcon: React.FunctionComponent<IRepeatIconProps> = ({
  color = "#E29314"
}) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.83398 6V7.33333C1.83398 7.73333 2.10065 8 2.50065 8C2.90065 8 3.16732 7.73333 3.16732 7.33333V6C3.16732 4.86667 4.03398 4 5.16732 4H12.9007L11.3673 5.53333C11.1007 5.8 11.1007 6.2 11.3673 6.46667C11.5007 6.6 11.634 6.66667 11.834 6.66667C12.034 6.66667 12.1673 6.6 12.3007 6.46667L14.9673 3.8C15.0007 3.76667 15.0173 3.73333 15.034 3.7C15.0507 3.66667 15.0673 3.63333 15.1007 3.6C15.1673 3.4 15.1673 3.2 15.1007 3.06667C15.1007 3 15.034 2.93333 14.9673 2.86667L12.3007 0.2C12.034 -0.0666667 11.634 -0.0666667 11.3673 0.2C11.1007 0.466667 11.1007 0.866667 11.3673 1.13333L12.9007 2.66667H5.16732C3.30065 2.66667 1.83398 4.13333 1.83398 6ZM13.834 8.66667C13.834 8.26667 14.1007 8 14.5007 8C14.9007 8 15.1673 8.26667 15.1673 8.66667V10C15.1673 11.8667 13.7007 13.3333 11.834 13.3333H4.10065L5.63398 14.8667C5.90065 15.1333 5.90065 15.5333 5.63398 15.8C5.50065 15.9333 5.36732 16 5.16732 16C4.96732 16 4.83398 15.9333 4.70065 15.8L2.03398 13.1333C2.00065 13.1 1.98398 13.0667 1.96732 13.0333C1.95065 13 1.93398 12.9667 1.90065 12.9333C1.83398 12.7333 1.83398 12.5333 1.90065 12.4C1.90065 12.3333 1.96731 12.2667 2.03398 12.2L2.03398 12.2L4.70065 9.53333C4.96732 9.26667 5.36732 9.26667 5.63398 9.53333C5.90065 9.8 5.90065 10.2 5.63398 10.4667L4.10065 12H11.834C12.9673 12 13.834 11.1333 13.834 10V8.66667Z"
        fill={color}
      />
      <mask
        id="mask0_652_23972"
        maskUnits="userSpaceOnUse"
        x="1"
        y="0"
        width="15"
        height="16"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.83398 6V7.33333C1.83398 7.73333 2.10065 8 2.50065 8C2.90065 8 3.16732 7.73333 3.16732 7.33333V6C3.16732 4.86667 4.03398 4 5.16732 4H12.9007L11.3673 5.53333C11.1007 5.8 11.1007 6.2 11.3673 6.46667C11.5007 6.6 11.634 6.66667 11.834 6.66667C12.034 6.66667 12.1673 6.6 12.3007 6.46667L14.9673 3.8C15.0007 3.76667 15.0173 3.73333 15.034 3.7C15.0507 3.66667 15.0673 3.63333 15.1007 3.6C15.1673 3.4 15.1673 3.2 15.1007 3.06667C15.1007 3 15.034 2.93333 14.9673 2.86667L12.3007 0.2C12.034 -0.0666667 11.634 -0.0666667 11.3673 0.2C11.1007 0.466667 11.1007 0.866667 11.3673 1.13333L12.9007 2.66667H5.16732C3.30065 2.66667 1.83398 4.13333 1.83398 6ZM13.834 8.66667C13.834 8.26667 14.1007 8 14.5007 8C14.9007 8 15.1673 8.26667 15.1673 8.66667V10C15.1673 11.8667 13.7007 13.3333 11.834 13.3333H4.10065L5.63398 14.8667C5.90065 15.1333 5.90065 15.5333 5.63398 15.8C5.50065 15.9333 5.36732 16 5.16732 16C4.96732 16 4.83398 15.9333 4.70065 15.8L2.03398 13.1333C2.00065 13.1 1.98398 13.0667 1.96732 13.0333C1.95065 13 1.93398 12.9667 1.90065 12.9333C1.83398 12.7333 1.83398 12.5333 1.90065 12.4C1.90065 12.3333 1.96731 12.2667 2.03398 12.2L2.03398 12.2L4.70065 9.53333C4.96732 9.26667 5.36732 9.26667 5.63398 9.53333C5.90065 9.8 5.90065 10.2 5.63398 10.4667L4.10065 12H11.834C12.9673 12 13.834 11.1333 13.834 10V8.66667Z"
          fill={color}
        />
      </mask>
      <g mask="url(#mask0_652_23972)">
        <rect x="0.5" width="16" height="16" fill={color} />
      </g>
    </svg>
  )
}

export default RepeatIcon
