import * as React from "react";

import { ASSETS_URL } from "@/constants";
import { Stack } from "@mui/material";

interface ILogoProps {}

const Logo: React.FunctionComponent<ILogoProps> = (props) => {
  return (
    <Stack alignItems="center" justifyContent="center">
      <img
        src={`${ASSETS_URL}/h-sepehrino-100.jpg`}
        alt={"Logo"}
        width={140}
        height={40}
      />
    </Stack>
  );
};

export default Logo;
