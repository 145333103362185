// Built-in import
import * as React from "react"

// External import
import { Paper, SxProps } from "@mui/material"

// Internal import
import DetailsCard from "./details"
import { IMedia } from "@/types/media"
import { IServerProperty } from "@/store/slices/property"
import PropertyThumbnail from "./details/thumbnail"
import { useMedia } from "@/hook"

interface IPortraitPropertyCard {
  item?: IServerProperty
  sxProps?: SxProps
}

const PortraitPropertyCard: React.FC<IPortraitPropertyCard> = ({
  item,
  sxProps
}) => {
  const background = useMedia(item.media, "background") as IMedia
  const defBackground = useMedia(item.media, "default") as IMedia
  const targetMedia = background?.original_url || defBackground?.original_url

  return (
    <Paper
      sx={{
        p: 0,
        border: "1px solid",
        borderColor: (t) => t.palette.grey[200],
        borderRadius: 4,
        boxShadow: "0px 0px 10px #99999915",
        overflow: "hidden",
        maxHeight: 645,
        background: (t) => t.palette.common.white,
        "& img": {
          height: "200px",
          width: "auto"
        },
        ...sxProps
      }}
    >
      {/* image card */}
      <PropertyThumbnail src={targetMedia} />
      {/* description card */}
      <DetailsCard item={item} />
    </Paper>
  )
}

export default PortraitPropertyCard
