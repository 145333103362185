import { api, getEndpoint } from "@/api";

class Auth {
  public static LoginViaCredential(mobile: string, password: string) {
    return api.post(getEndpoint("loginViaCredential"), {
      mobile,
      password,
    });
  }
  public static LoginViaMobile(mobile: string) {
    return api.post(getEndpoint("loginViaMobile"), {
      mobile,
    });
  }
  public static ResetPasswordViaMobile(mobile: string, recaptcha: string) {
    return api.post(getEndpoint("loginViaMobile"), {
      mobile,
      is_reset_password: true,
      recaptcha,
    });
  }
  public static ConfirmOtp(mobile: string, otp: string) {
    return api.post(getEndpoint("confirmOtp"), {
      mobile,
      otp,
    });
  }
}
export default Auth;
