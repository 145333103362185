import { IIconType } from "@/types/icon";
import * as React from "react";

interface IUnlockIconProps extends IIconType {}

const UnlockIcon: React.FunctionComponent<IUnlockIconProps> = ({
  color = "#5E5873",
  width = 24,
  height = 24,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 10H19C20.7 10 22 11.3 22 13V20C22 21.7 20.7 23 19 23H5C3.3 23 2 21.7 2 20V13C2 11.3 3.3 10 5 10H6V7C6 5.4 6.6 3.9 7.8 2.8C8.9 1.6 10.4 1 12 1C14.8 1 17.3 3 17.9 5.8C18 6.4 17.6 6.9 17.1 7C16.6 7.1 16 6.7 15.9 6.2C15.5 4.3 13.9 3 12 3C10.9 3 9.9 3.4 9.2 4.2C8.4 4.9 8 5.9 8 7V10ZM19 21C19.6 21 20 20.6 20 20V13C20 12.4 19.6 12 19 12H5C4.4 12 4 12.4 4 13V20C4 20.6 4.4 21 5 21H19Z"
        fill="black"
      />
      <mask
        id="mask0_708_33970"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="1"
        width="20"
        height="22"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8 10H19C20.7 10 22 11.3 22 13V20C22 21.7 20.7 23 19 23H5C3.3 23 2 21.7 2 20V13C2 11.3 3.3 10 5 10H6V7C6 5.4 6.6 3.9 7.8 2.8C8.9 1.6 10.4 1 12 1C14.8 1 17.3 3 17.9 5.8C18 6.4 17.6 6.9 17.1 7C16.6 7.1 16 6.7 15.9 6.2C15.5 4.3 13.9 3 12 3C10.9 3 9.9 3.4 9.2 4.2C8.4 4.9 8 5.9 8 7V10ZM19 21C19.6 21 20 20.6 20 20V13C20 12.4 19.6 12 19 12H5C4.4 12 4 12.4 4 13V20C4 20.6 4.4 21 5 21H19Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_708_33970)">
        <rect width={width - 1} height={width - 1} fill={color} />
      </g>
    </svg>
  );
};

export default UnlockIcon;
