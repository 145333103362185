import * as React from "react";
import { Stack, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

interface IPerformanceReportActionButtonsProps {
  handleNextStep?: Function;
  handlePrevStep?: Function;
  submtBtnTitle?: string;
}

export const PerformanceReportActionButtons: React.FunctionComponent<
  IPerformanceReportActionButtonsProps
> = (props) => {
  //getting translation object
  const { t } = useTranslation();
  const {
    handleNextStep,
    handlePrevStep,
    submtBtnTitle = t("confirm"),
  } = props;
  return (
    <Stack
      direction={"row"}
      spacing={1}
      alignItems="center"
      width="100%"
      justifyContent={"flex-end"}
    >
      {handlePrevStep ? (
        <Button variant="outlined" onClick={() => handlePrevStep()}>
          {t("return")}
        </Button>
      ) : null}

      {handleNextStep ? (
        <Button variant="outlined" onClick={() => handleNextStep()}>
          {submtBtnTitle}
        </Button>
      ) : null}
    </Stack>
  );
};
