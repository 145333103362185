import * as React from "react"

import { Box, Stack, Typography, alpha } from "@mui/material"
import { humanDate, humanDateToTime } from "@/utils"

import DatePicker from "@amir04lm26/react-modern-calendar-date-picker"
import moment from "moment-jalaali"
import { paletteModeSelector } from "@/store/slices/settings/selectors"
import { useAppSelector } from "@/store/store"

interface IMyDatepickerProps {
  onChange?: Function
  onChangeDate?: Function
  onChangeJalali?: Function
  title?: string
  value?: any
  disabled?: boolean
}

const MyDatepicker: React.FunctionComponent<IMyDatepickerProps> = ({
  onChange,
  onChangeDate,
  onChangeJalali,
  value,
  disabled = false,
  title = "Select date ..."
}) => {
  //Getting palette mode
  const paletteMode = useAppSelector(paletteModeSelector)

  const [selectedDay, setSelectedDay] = React.useState(null)

  React.useEffect(() => {
    if (selectedDay && onChange) onChange(selectedDay)

    if (selectedDay && onChangeDate) {
      const mDate = humanDateToTime(
        `${selectedDay?.year}-${selectedDay?.month}-${selectedDay?.day}`
      )
      onChangeDate(mDate)
    }
    if (selectedDay && onChangeJalali) {
      const mDate = moment.from(
        `${selectedDay?.year}-${selectedDay?.month}-${selectedDay?.day}`,
        "fa",
        "YYYY-MM-DD"
      )
      onChangeJalali(mDate)
    }
  }, [selectedDay])

  React.useEffect(() => {
    if (value && selectedDay === null) {
      const momentedDate = humanDate(value)?.split("-")
      setSelectedDay({
        year: +momentedDate?.[0],
        month: +momentedDate?.[1],
        day: +momentedDate?.[2]
      })
    }
  }, [value, selectedDay])

  //Appropriate color
  const colorReadable = paletteMode === "light" ? "#000" : "#fff"

  return (
    <Box
      sx={{
        width: "100%",
        [`& .DatePicker`]: {
          width: "100%",
          [`& input`]: {
            width: "100%",
            borderRadius: "5px",
            height: 48,
            textAlign: "left",
            textIndent: 8,
            fontSize: 14,
            [`&::placeholder`]: {
              color: (t) => alpha(t.palette.common.black, 0.7)
            }
          }
        },
        [`& .DatePicker__input`]: {
          bgcolor: (t) => (paletteMode !== "light" ? "transparent" : "#fff"),
          borderColor: (t) =>
            paletteMode !== "light"
              ? "rgba(255,255,255,0.23)"
              : "rgba(0,0,0,0.23)",
          color: colorReadable
        },
        [`& .Calendar`]: {
          bgcolor: (t) => (paletteMode !== "light" ? "#000" : "#fff")
        },
        [`& .Calendar__monthArrowWrapper .Calendar__monthArrow`]: {
          bgcolor: (t) => "#fff"
        },
        [`& .Calendar__day, .Calendar__header button`]: {
          color: (t) => colorReadable,
          [`&.-selected`]: {
            background: (t) => t.palette.primary.main,
            color: (t) => t.palette.common.white
          }
        }
      }}
    >
      {disabled ? (
        <Stack
          direction="row"
          sx={{
            border: "1px solid",
            borderColor: (t) => alpha(t.palette.common.black, 0.25),
            borderRadius: "6px",
            p: 1
          }}
        >
          <Typography>
            {selectedDay
              ? `${selectedDay?.year}/${selectedDay?.month}/${selectedDay?.day}`
              : "-"}
          </Typography>
        </Stack>
      ) : (
        <DatePicker
          value={selectedDay}
          onChange={(date) => setSelectedDay(date)}
          shouldHighlightWeekends
          inputPlaceholder={title}
          locale="fa"
        />
      )}
    </Box>
  )
}

export default React.memo(MyDatepicker)
