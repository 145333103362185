import * as React from "react"

interface ICloseCircleIconProps {
  color?: string
}

const CloseCircleIcon: React.FunctionComponent<ICloseCircleIconProps> = ({
  color = "#fff"
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 1C5.9 1 1 5.9 1 12C1 18.1 5.9 23 12 23C18.1 23 23 18.1 23 12C23 5.9 18.1 1 12 1ZM12 21C7 21 3 17 3 12C3 7 7 3 12 3C17 3 21 7 21 12C21 17 17 21 12 21ZM13.4 12L15.7 9.7C16.1 9.3 16.1 8.7 15.7 8.3C15.3 7.9 14.7 7.9 14.3 8.3L12 10.6L9.7 8.3C9.3 7.9 8.7 7.9 8.3 8.3C7.9 8.7 7.9 9.3 8.3 9.7L10.6 12L8.3 14.3C7.9 14.7 7.9 15.3 8.3 15.7C8.5 15.9 8.7 16 9 16C9.3 16 9.5 15.9 9.7 15.7L12 13.4L14.3 15.7C14.5 15.9 14.7 16 15 16C15.3 16 15.5 15.9 15.7 15.7C16.1 15.3 16.1 14.7 15.7 14.3L13.4 12Z"
        fill={color}
      />
      <mask
        id="mask0_668_30877"
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="22"
        height="22"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 1C5.9 1 1 5.9 1 12C1 18.1 5.9 23 12 23C18.1 23 23 18.1 23 12C23 5.9 18.1 1 12 1ZM12 21C7 21 3 17 3 12C3 7 7 3 12 3C17 3 21 7 21 12C21 17 17 21 12 21ZM13.4 12L15.7 9.7C16.1 9.3 16.1 8.7 15.7 8.3C15.3 7.9 14.7 7.9 14.3 8.3L12 10.6L9.7 8.3C9.3 7.9 8.7 7.9 8.3 8.3C7.9 8.7 7.9 9.3 8.3 9.7L10.6 12L8.3 14.3C7.9 14.7 7.9 15.3 8.3 15.7C8.5 15.9 8.7 16 9 16C9.3 16 9.5 15.9 9.7 15.7L12 13.4L14.3 15.7C14.5 15.9 14.7 16 15 16C15.3 16 15.5 15.9 15.7 15.7C16.1 15.3 16.1 14.7 15.7 14.3L13.4 12Z"
          fill={color}
        />
      </mask>
      <g mask="url(#mask0_668_30877)">
        <rect width="24" height="24" fill={color} />
      </g>
    </svg>
  )
}

export default CloseCircleIcon
