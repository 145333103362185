// Built-in import
import * as React from "react";
// External import
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
// Internal import
import { TitleWithValue, VerifyRejectionButton } from "@/components/shared";
import { Report } from "@/services";
import { IEvent } from "@/types/event";
import { toaster } from "../../toaster";

interface IChildrenWaitingPlan {
  item: IEvent;
}

const ChildrenWaitingPlan: React.FC<IChildrenWaitingPlan> = ({ item }) => {
  // Getting props as item
  // Getting translation object
  const { t } = useTranslation(["common"]);

  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [rejectLoading, setRejectLoading] = React.useState(false);

  const handleConfirmEvent = async () => {
    setConfirmLoading(true);
    try {
      const res = await Report.ConfirmEvent(item.id, item.type);
      toaster.success(res.data.message);
      window.location.reload();
    } catch (error) {
      toaster.error(error.response.data);
    } finally {
      setConfirmLoading(false);
    }
  };
  const handleRejectEvent = async () => {
    setRejectLoading(true);
    try {
      const res = await Report.RejectEvent(item.id, item.type);
      toaster.success(res.data.message);
      window.location.reload();
    } catch (error) {
      toaster.error(error.response.data);
    } finally {
      setRejectLoading(false);
    }
  };
  return (
    <Stack direction="column" pb={1} spacing={1}>
      <Typography variant="bodyMedium">{item.title || "-"}</Typography>
      {item.expires_at && (
        <Typography variant="bodyMedium">
          {t("common:anticipated_event")}
        </Typography>
      )}
      <Typography variant="bodyMedium">{`${t(
        "common:awaiting_approval_of_plan_by_system_administrator"
      )}...`}</Typography>
      {item.revenue_percent && (
        <TitleWithValue
          title={t("common:depsoited_profit")}
          value={`${item.revenue_percent}%`}
          hasDoubleDot={false}
          justifyContent="space-between"
          colorTitle={"text.secondary"}
          variantTitle="bodyBold"
          variantValue={"bodyMedium"}
          alignItems="flex-start"
        />
      )}

      <VerifyRejectionButton
        verifyOnclick={handleConfirmEvent}
        rejectionOnclick={handleRejectEvent}
        confirmloading={confirmLoading}
        rejectloading={rejectLoading}
        rejectionTitle={t("common:reject_event")}
        verifyTitle={t("common:confirm_event")}
      />
    </Stack>
  );
};

export default ChildrenWaitingPlan;
