//Built-in import
import * as React from "react"

//External import
import { Stack, Typography, alpha } from "@mui/material"

import ChatBackground from "@/assets/images/chat-Background.png"
import ChatboxMessageItem from "./item"
import { ITicketMessage } from "@/types/ticket"
import { useTranslation } from "react-i18next"

//Internal import


interface IChatboxMeessagesProps {
  items: ITicketMessage[]
  ref: React.Ref<HTMLDivElement>
}

const ChatboxMeessages: React.FunctionComponent<IChatboxMeessagesProps> =
  React.forwardRef(({ items }, ref) => {
    //Getting translation obbject
    const { t } = useTranslation(["common"])

    return items.length ? (
      <Stack
        sx={{
          p: 2,
          overflowY: "auto",
          height: "100%"
        }}
        spacing={0.5}
        component="div"
        ref={ref}
      >
        {items?.map((message, key) => (
          <ChatboxMessageItem
            beforeMessage={key > 0 ? items?.[key - 1] : null}
            item={message}
            key={message.id}
          />
        ))}
      </Stack>
    ) : (
      <Stack sx={{ p: 2 }}>
        <Typography>{t("common:no_message_yet")}</Typography>
      </Stack>
    )
  })

export default ChatboxMeessages
