import * as React from "react";

import {
  Box,
  Paper,
  Stack,
  SxProps,
  Theme,
  Typography,
  alpha,
} from "@mui/material";

// Built-in import
import { AllVariants } from "@/mui/theme/declarations";
// External Import
// Internal Import

interface IBoxWithTitleProps {
  title: string;
  variantTitle?: AllVariants;
  meta?: React.ReactNode;
  children: React.ReactNode;
  sxProps?: SxProps<Theme>;
  spacing?: number | string;
}

const BoxWithTitle: React.FunctionComponent<IBoxWithTitleProps> = ({
  title,
  variantTitle = "subtitleBold",
  meta,
  children,
  sxProps = {},
  spacing = 0,
}) => {
  return (
    <Paper
      sx={{
        p: 0,
        bgcolor: (t) => t.palette.common.white,
        borderRadius: "12px",
        border: "1px solid",
        borderColor: (t) => t.palette.grey[300],
        ...sxProps,
      }}
    >
      <Stack spacing={spacing}>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent="space-between"
          p={3}
          className="title-box"
        >
          <Typography variant={variantTitle} color="text.primary">
            {title}
          </Typography>
          {meta}
        </Stack>
        <Box className="children-holder">{children}</Box>
      </Stack>
    </Paper>
  );
};

export default BoxWithTitle;
