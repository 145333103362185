import * as React from "react";

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  SxProps,
  Theme,
} from "@mui/material";

import { IRadioItem } from "@/types/radios";

interface IRadiosProps {
  items: IRadioItem[];
  defaultValue?: any;
  onChange?: (value: string) => void;
  sxProps?: SxProps<Theme>;
  direction?: string;
}

const Radios: React.FunctionComponent<IRadiosProps> = ({
  items,
  defaultValue = "",
  onChange,
  sxProps = {},
  direction = "column",
}) => {
  const [value, setValue] = React.useState(null);
  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(value);
    if (onChange) onChange(value);
  };

  //Fire when we set new value and we want to pass that to parent component
  // React.useEffect(() => {
  //   if (onChange) onChange(value)
  // }, [value])

  return (
    <FormControl sx={{ ...sxProps }}>
      <RadioGroup
        aria-labelledby="controlled-radio-buttons-group"
        onChange={handleChangeRadio}
        sx={{ flexDirection: direction }}
        value={value}
      >
        {items.map((item) => {
          return (
            <FormControlLabel
              control={<Radio />}
              key={item.id}
              value={item.value}
              label={item.title}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default Radios;
