// Built-in import
import * as React from "react";

// External import
import { Stack } from "@mui/material";
// Internal import
import { TitleWithValue } from "@/components/shared";
import { useTranslation } from "react-i18next";
import { IProperty } from "@/types/properties";

interface IPropertiesCardDetailsSuccess {
  item: IProperty;
}

const PropertiesCardDetailsSuccess: React.FC<IPropertiesCardDetailsSuccess> = ({
  item,
}) => {
  // Getting translation object
  const { t } = useTranslation(["common"]);
  // Getting props as values

  const raisedAmount = +item.investment_ratio * +item.sold_shares;

  return (
    <Stack
      width={"100%"}
      sx={{
        borderLeft: ["none", , , "1px solid #99999940"],
        px: [0, , , 1.5],
        [`& .title-with-value:first-child`]: {
          pt: 0,
        },
        [`& .title-with-value:last-child`]: {
          pb: 0,
        },
        [`& .title-with-value`]: {
          borderBottom: "1px solid #99999940",
          py: "18px",
          "&:last-child": {
            borderBottom: "none",
          },
        },
      }}
    >
      <TitleWithValue
        title={t("common:raised_capital")}
        value={raisedAmount.withCommas().withPriceUnit()}
        variantTitle="bodyMedium"
        variantValue="bodyBold"
        colorValue="black"
        justifyContent="space-between"
      />

      <TitleWithValue
        title={t("common:number_of_investors")}
        value={`%${10}`}
        variantTitle="bodyMedium"
        variantValue="bodyBold"
        colorValue="black"
        justifyContent="space-between"
      />

      <TitleWithValue
        title={t("common:remaining_until_end_of_plan")}
        value={item?.created_at}
        variantTitle="bodyMedium"
        variantValue="bodyBold"
        colorValue="black"
        justifyContent="space-between"
      />

      <TitleWithValue
        title={t("common:next_financial_event")}
        value={""}
        variantTitle="bodyMedium"
        variantValue="bodyBold"
        colorValue="black"
        justifyContent="space-between"
      />
    </Stack>
  );
};

export default PropertiesCardDetailsSuccess;
