//Built-in Import
import * as React from "react";
//External Import
import { Stack, Box, Typography } from "@mui/material";
//Internal Import

interface ITipsProps {
  title: string;
  color?: string;
  size?: "small" | "medium";
}

const Tips: React.FunctionComponent<ITipsProps> = ({
  title = "",
  color = "text.primary",
  size = "small",
}) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Box
        sx={{
          width: size == "small" ? 10 : 18,
          height: size == "small" ? 10 : 18,
          bgcolor: color,
          borderRadius: "100%",
        }}
      ></Box>
      <Typography
        variant={size == "small" ? "extraSmallMedium" : "smallMedium"}
        color="text.primary"
      >
        {title}
      </Typography>
    </Stack>
  );
};

export default Tips;
