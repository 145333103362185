import * as React from "react"

import { ASSETS_URL } from "@/constants"
import { Stack } from "@mui/material"

interface IPropertyThumbnailProps {
  src: string
}

const PropertyThumbnail: React.FunctionComponent<IPropertyThumbnailProps> = ({
  src
}) => {
  return (
    <Stack
      sx={{
        borderBottom: 1,
        borderColor: (t) => t.palette.divider,
        [`& img`]: {
          objectFit: "contain"
        }
      }}
    >
      <img src={src || `${ASSETS_URL}/h-sepehrino-100.jpg`} alt="" />
    </Stack>
  )
}

export default PropertyThumbnail
