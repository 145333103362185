import { Box, Pagination, alpha } from "@mui/material"
import { useEffect, useState } from "react"

import { WithPaginationProps } from "./interface"

const defaultPage = 1

export function withPagination<
  T extends WithPaginationProps = WithPaginationProps
>(WrappedComponent: React.ComponentType<T>) {
  const ComponentWithPagination = (
    props: Omit<T, keyof WithPaginationProps>
  ) => {
    //Pagination
    const [page, setPage] = useState(defaultPage)
    const handleChangePage = (
      event: React.ChangeEvent<unknown> | null,
      newPage: number
    ) => {
      setPage(newPage)
    }

    const renderPagination = (count: number) => {
      return count ? (
        <Pagination
          className="MyPagination"
          sx={{
            display: "flex",
            my: 5,
            mb: 0,
            justifyContent: "center",
            alignItems: "start",
            ["& .muirtl-tjm9mo-MuiButtonBase-root-MuiPaginationItem-root.Mui-disabled"]:
              {
                opacity: 0
              },
            ["& ul li button"]: {
              transition: "0.3s"
            },
            ["& ul li:first-child button, ul li:last-child button"]: {
              borderRadius: "50px",
              mx: 0.5,
              color: (t) => t.palette.primary.main
            },
            ["& ul li:nth-child(2) button"]: {
              borderTopLeftRadius: "20px",
              borderBottomLeftRadius: "20px"
            },
            ["& ul li:nth-last-child(2) button"]: {
              borderTopRightRadius: "20px",
              borderBottomRightRadius: "20px"
            },
            [`& .MuiPaginationItem-root`]: {
              background: (t) => alpha(t.palette.common.black, 0.05),
              borderRadius: 0,
              mx: 0,
              height: 32,
              display: "flex",
              alignItems: "center",
              justifyContent: "cennter"
            },
            [`& .Mui-selected`]: {
              bgcolor: (t) => `${t.palette.primary.main} !important`,
              color: (t) => `${t.palette.common.white} !important`,
              fontWeight: "medium",
              borderRadius: "50px !important",
              transform: "scale(1.2)"
            },
            ["& ul li:has(> .Mui-selected)"]: {
              background: (t) => alpha(t.palette.common.black, 0.05)
            }
          }}
          count={count}
          color="primary"
          onChange={handleChangePage}
          page={page}
        />
      ) : null
    }

    useEffect(() => {
      setPage(defaultPage)
    }, [])

    return (
      <Box sx={{ width: "100%" }}>
        <WrappedComponent
          {...(props as T)}
          page={page}
          renderPagination={renderPagination}
        />
      </Box>
    )
  }

  return ComponentWithPagination
}
