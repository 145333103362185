// Built-in import
import * as React from "react"

// External import
import { Stack, Typography, alpha } from "@mui/material"

import { useTranslation } from "react-i18next"

interface IPriceCardProps {
  price: string | number
}

const PriceCard: React.FC<IPriceCardProps> = ({ price }) => {
  // Getting translation object
  const { t } = useTranslation(["common"])
  return (
    <Stack
      direction="row"
      justifyContent={"space-between"}
      flexWrap="wrap"
      sx={{
        background: (t) => alpha(t.palette.primary.main, 0.2),
        p: 1,
        borderRadius: 1
      }}
    >
      <Typography variant="smallMedium" color="text.primary">
        {t("common:min_required_capital")}:
      </Typography>
      <Typography variant="smallMedium" color="text.primary">
        {price.withCommas().withPriceUnit()}
      </Typography>
    </Stack>
  )
}

export default PriceCard
