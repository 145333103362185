import * as React from "react"

import NumberFormat from "react-number-format"
import { TextField } from "@mui/material"

const TextFieldNumberFormat = (props) => {
  return (
    <NumberFormat
      {...props}
      customInput={TextField}
      prefix={""}
      type="text"
      thousandSeparator={","}
    />
  )
}

export default TextFieldNumberFormat
