import { CacheProvider } from "@emotion/react"
import createCache from "@emotion/cache"
import rtlPlugin from "stylis-plugin-rtl"

// Create rtl cache
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [rtlPlugin]
})

interface RTLProps {
  children: React.ReactNode
}

const RTL: React.FC<RTLProps> = (props) => {
  return <CacheProvider value={cacheRtl}>{props.children}</CacheProvider>
}

export default RTL
