// Built-in import
import * as React from "react";
// External import
import { Stack } from "@mui/material";
// internal import
import { TitleWithValue } from "@/components/shared";
import { useTranslation } from "react-i18next";
import { IEvent } from "@/types/event";
import EventStatus from "./status";

interface IChildrenStructureTitle {
  cardTitle: string;
  cardMeta?: string;
  children: React.ReactNode;
  event?: IEvent;
}

const ChildrenStructureTitle: React.FC<IChildrenStructureTitle> = ({
  cardTitle,
  cardMeta,
  children,
  event,
}) => {
  const { t } = useTranslation();
  //checking for select deleted item from client and awaiting admin confirmation
  const isPendingDelete = Boolean(
    event.status === "Deleted" && event.deleted_at === null
  );
  return (
    <Stack
      sx={{
        flexGrow: 1,
        bgcolor: (t) => t.palette.common.white,
        borderRadius: "9px",
        border: "1px solid",
        borderColor: (t) => t.palette.grey[300],
        width: "100%",
        height: "100%",
      }}
    >
      <Stack
        sx={{
          px: 1.5,
          py: 1,
          my: 1,
          borderBottom: "1px solid",
          borderColor: (t) => t.palette.grey[300],
        }}
      >
        <TitleWithValue
          title={cardTitle}
          value={cardMeta}
          hasDoubleDot={false}
          justifyContent="space-between"
          colorTitle="text.secondary"
          colorValue="text.secondary"
          variantTitle="smallMedium"
          variantValue="smallMedium"
        />
      </Stack>
      <Stack px={1.5} py={1}>
        <TitleWithValue
          title={t("status")}
          value={
            <EventStatus
              status={isPendingDelete ? t("pending_delete") : event?.status}
            />
          }
          direction={"row"}
          justifyContent="space-between"
          colorTitle="text.secondary"
          colorValue="text.secondary"
          variantTitle="smallMedium"
          variantValue="smallMedium"
        />
        {children}
      </Stack>
    </Stack>
  );
};

export default ChildrenStructureTitle;
