import * as React from "react";

import { EditPerformanceReportForm } from "./form";
import ModalContentWrapper from "@/components/shared/modal/modal-content-wrapper";
import { useTranslation } from "react-i18next";

interface IEditPerformanceReportProps {
  projectId: string;
  onFetchAllEvents: () => void;
}

export const EditPerformanceReport: React.FunctionComponent<
  IEditPerformanceReportProps
> = ({ projectId, onFetchAllEvents }) => {
  //get translation object
  const { t } = useTranslation();

  return (
    <ModalContentWrapper
      title={t("create_event_report")}
      titleVariant="subtitleBold"
    >
      <EditPerformanceReportForm
        projectId={projectId}
        onFetchAllEvents={onFetchAllEvents}
      />
    </ModalContentWrapper>
  );
};
