import * as React from "react"
import { Skeleton, TableCell, TableRow } from "@mui/material"
import { LISTING_PER_PAGE } from "@/constants"
import { ITableItems } from "@/types/table"

interface IMyTableSkeletonProps {
  selectable?: boolean
  tableHeadItems: ITableItems[]
}

export const MyTableSkeleton: React.FunctionComponent<
  IMyTableSkeletonProps
> = ({ selectable = false, tableHeadItems = [] }) => {
  //Getting defined LISTING_PER_PAGE
  const per_page = LISTING_PER_PAGE
  return (
    <>
      {Array.from(Array(per_page).keys()).map((item) => (
        <TableRow key={item}>
          {/** Selectable checkbox */}
          {selectable && (
            <TableCell>
              <Skeleton
                sx={{ my: 1, mx: "auto" }}
                width={24}
                height={24}
                variant="rectangular"
              />
            </TableCell>
          )}
          {/** Selectable checkbox */}
          {tableHeadItems.map((tdItem, tdKey) => (
            <TableCell key={tdKey}>
              <Skeleton
                sx={{ my: 1, mx: "auto" }}
                key={tdKey}
                width={100}
                height={24}
                variant="rectangular"
              />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  )
}
