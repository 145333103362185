import * as React from "react";
import { Stack } from "@mui/material";

interface IAccordionSummaryPartialProps {
  children: React.ReactNode;
  spacing?: number;
  placement?: string;
}

const AccordionSummaryPartial: React.FunctionComponent<
  IAccordionSummaryPartialProps
> = ({ children, spacing, placement = "space-between" }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent={placement}
      width={"100%"}
      spacing={spacing}
    >
      {children}
    </Stack>
  );
};

export default AccordionSummaryPartial;
