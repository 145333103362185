import * as React from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import ModalContentWrapper from "@/components/shared/modal/modal-content-wrapper";
import AddReport from "./add-report";
import ForcastDate from "./forcast-date";
import SaveReportEvents from "./save-events";

interface IPerformanceReport {}

export enum AddProfitDepositTypes {
  AddReport = 0,
  ForcastDate = 1,
  SaveEvents = 2,
}

export const PerformanceReport: React.FC<IPerformanceReport> = () => {
  //Getting translation object
  const { t } = useTranslation();
  // steps
  const [step, setStep] = React.useState<number | null>(
    AddProfitDepositTypes.AddReport
  );

  const RenderContent = () => {
    switch (step) {
      case AddProfitDepositTypes.AddReport:
        return <AddReport changeStepHandler={(value) => setStep(value)} />;
      case AddProfitDepositTypes.ForcastDate:
        return <ForcastDate changeStepHandler={(value) => setStep(value)} />;
      case AddProfitDepositTypes.SaveEvents:
        return (
          <SaveReportEvents changeStepHandler={(value) => setStep(value)} />
        );
      default:
        return <AddReport changeStepHandler={(value) => setStep(value)} />;
    }
  };

  return (
    <ModalContentWrapper
      title={t("predicting_date_of_deposit_of_interest")}
      titleVariant="subtitleBold"
    >
      <Stack
        minHeight={{ xs: "calc(100vh - 100px)", md: 400 }}
        justifyContent={"space-between"}
        width={{ xs: "100%", md: "800px" }}
        alignItems="start"
      >
        <RenderContent />
      </Stack>
    </ModalContentWrapper>
  );
};
