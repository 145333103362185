// Built-in import
import * as React from "react";
// External import
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface INoItemSelected {}

const NoItemSelected: React.FC<INoItemSelected> = () => {
  // getting translation object
  const { t } = useTranslation(["common"]);
  return (
    <Stack
      alignItems="center"
      justifyContent={"center"}
      textAlign="center"
      height="auto"
    >
      <Typography variant="subtitleRegular" color="text.primary">
        {t("common:click_on_each_event_in_calendar_to_view_its_details")}
      </Typography>
    </Stack>
  );
};

export default NoItemSelected;
