import * as React from "react"

import { Stack, Typography } from "@mui/material"

import { EditMobileNumber } from "./edit-mobile-number"
import OtpHandler from ".."
import { useTranslation } from "react-i18next"

interface IOtpHandlerHolderProps {
  handleChangeOtp?: Function
  hasEditMobile?: boolean
}

const OtpHandlerHolder: React.FunctionComponent<IOtpHandlerHolderProps> = ({
  handleChangeOtp,
  hasEditMobile = false
}) => {
  //Getting translation object
  const { t } = useTranslation()
  return (
    <Stack spacing={2} alignItems="start" width={"100%"}>
      <Typography variant="smallRegular" color={"text.secondary"}>
        {t("enter_six_digit_otp_code")}
      </Typography>
      <OtpHandler length={6} onChange={(val) => handleChangeOtp(val)} />
      {/**check for has edit mobile number */}
      {(hasEditMobile && <EditMobileNumber />) || null}
      {/**check for has edit mobile number */}
    </Stack>
  )
}

export default React.memo(OtpHandlerHolder)
