import * as React from "react"

interface ILazyRoute {
  element: React.ReactNode
}
const LazyRoute: React.FunctionComponent<ILazyRoute> = ({ element }) => (
  <React.Suspense>{element}</React.Suspense>
)

export default LazyRoute
