import * as React from "react";
import { Stack, SxProps, Theme, Typography } from "@mui/material";
import { AllVariants } from "@/mui/theme/declarations";
interface ITitleWithValueProps {
  title: string | React.ReactNode;
  hasDoubleDot?: boolean;
  value: string | React.ReactNode;
  justifyContent?: "flex-end" | "flex-start" | "center" | "space-between";
  alignItems?: "flex-end" | "flex-start" | "center" | "space-between";
  direction?: "row" | "column" | "row-reverse" | "column-reverse" | any;
  colorTitle?: string;
  variantTitle?: AllVariants;
  variantValue?: AllVariants;
  spacing?: number | string;
  colorValue?: string;
  sxProps?: SxProps<Theme>;
}

const TitleWithValue: React.FunctionComponent<ITitleWithValueProps> = ({
  title,
  value,
  direction = "row",
  justifyContent = "flex-end",
  hasDoubleDot = true,
  alignItems = "center",
  sxProps = {},
  colorTitle = "black",
  variantTitle = "smallBold",
  variantValue = "bodyRegular",
  spacing = 1,
  colorValue = "text.secondary",
}) => {
  return (
    <Stack
      direction={direction}
      alignItems={alignItems}
      justifyContent={justifyContent}
      width={"100%"}
      spacing={spacing}
      className="title-with-value"
      sx={sxProps}
    >
      <Typography
        variant={variantTitle}
        whiteSpace={"nowrap"}
        color={colorTitle}
      >
        {title} {hasDoubleDot ? ":" : ""}
      </Typography>
      {Boolean(typeof value === "string" || typeof value === "number") ? (
        <Typography variant={variantValue} color={colorValue}>
          {value}
        </Typography>
      ) : (
        <>{value}</>
      )}
    </Stack>
  );
};

export default TitleWithValue;
